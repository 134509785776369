import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger/src';
import Reducers from './reducers/Reducers';

const store = configureStore({
  reducer: Reducers,
  devTools: process.env.NODE_ENV !== 'production',
  ...process.env.NODE_ENV === 'production' ? {
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  } : {},
});

export default store;
