import { size } from 'lodash';
import PageService from '../../../services/PageService';
import config from '../config';
import { replaceMergeFields } from '../util';

export const getList = (state) => state.list;
export const getListEdit = (state) => state.list.edit;
export const getListPagination = (state) => state.list.pagination;
export const getListIndex = (state) => state.list.index;
export const isListCompact = (state) => state.list.compact;

export const getListDimensionConnection = (state) => state.list.connection.dimensions;
export const getListLookupConnection = (state) => state.list.connection.lookup;
export const getDataConnection = (state) => state.list.connection.data;

export const getListPaginationWithData = (state, data) => ({
  ...getListPagination(state),
  totalSize: size(data),
});

/**
 * Returns a list of user settings that we want stored and to persist across page refreshes.
 */
export const getListPreferences = (state) => ({
  sizePerPage: state.list.pagination.sizePerPage,
  compact: state.list.compact,
});

/**
 * Replace a string with the list id replaced if the modal is open
 */
export const replacePageIdMergeFields = (string) => {
  if (!PageService.getObjectId()) {
    return string;
  }
  return replaceMergeFields({ [config.list.index.toLowerCase()]: PageService.getObjectId() }, string);
};
