import React, { useCallback, useEffect, useState } from 'react';

const getCSSClasses = (touched, errors) => {
  const classes = ['form-control form-control-solid'];

  if (touched && errors) {
    classes.push('is-invalid');
  }
  if (touched && !errors) {
    classes.push('is-valid');
  }
  return classes.join(' ');
};

const TextArea = (
  {
    onChange: onChangeProp = () => {},
    name,
    helpText,
    initialValue = '',
    placeholder,
    onBlur,
    touched,
    error,
    iconClass,
    onKeyDown = () => {},
    ...props
  },
) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  const getValue = () => value ?? '';

  const onChange = useCallback((e) => {
    setValue(e.target.value);
    onChangeProp(e.target.value);
  }, [onChangeProp]);

  return (
    <>
      <div className={iconClass && 'input-group-icon'}>
        {iconClass && <i className={iconClass} />}
        <textarea
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          name={name}
          rows={7}
          className={getCSSClasses(touched, error)}
          placeholder={placeholder}
          value={getValue()}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
      {helpText && <small className="form-text text-muted">{helpText}</small>}
    </>
  );
};

export default TextArea;
