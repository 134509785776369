import _ from 'lodash';
import { entitySaveAdapter } from '../reducers/SaveReducer';
import { replaceFilterMergeFields } from './FilterSelector';

export const {
  selectById: getSaveFieldsByName,
  selectIds: getSaveFieldsIds,
  selectEntities: getSaveFields,
  selectTotal: getSaveCount,
} = entitySaveAdapter.getSelectors((state) => state.save);

export const getSaveConnection = (state) => state.save.connection;

export const getSaveQuery = (state, row) => {
  const fields = getSaveFields(state);
  const connection = getSaveConnection(state);
  const chainedFields = _.chain(fields).map((field, key) => {
    let value;
    if (_.has(row, field.source)) {
      value = row[field.source];
    } else if (_.has(field, 'defaultValue')) {
      value = field.defaultValue;
    }

    switch (field.type) {
      case 'boolean':
        return `@${key}=${value === true || value === 1 || value === '1' ? 1 : 0}`;
      case 'integer':
        return `@${key}=${!_.isUndefined(value) ? _.toNumber(value) : 'undefined'}`;
      default:
        return _.isNull(value) ? `@${key}=${value}` : `@${key}='${value.replace(/'/g, '\'\'')}'`;
    }
  }).join(', ').value();

  const returnval = {
    datasource: connection.datasource,
    statement: replaceFilterMergeFields(state, `${connection.statement} ${chainedFields}`),
  };
  return returnval;
};
