import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _, {
  each, has, map, pickBy,
} from 'lodash';
import config from '../config';
import QueryService from '../../../services/QueryService';

export const entityDataFilterAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
  sortComparer: (a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0),
});

const createInitialState = () => entityDataFilterAdapter.addMany(
  entityDataFilterAdapter.getInitialState(),
  map(config.filter, (entity) => ({
    name: entity.name,
    options: [],
  })),
);

export const getDataFilter = createAsyncThunk(
  'dataFilter/get',
  async (connections) => {
    const connectionsFetch = pickBy(connections, (connection) => typeof connection.options === 'undefined');
    const filterFunctions = _.map(connectionsFetch, async (connection, name) => ({
      [name]: (await QueryService.run(connection)),
    }));

    const entities = [];
    const options = Object.assign({}, ...await Promise.all(filterFunctions));

    each(connections, (connection, name) => {
      entities.push({
        name,
        options: has(connectionsFetch, name)
          ? map(options[name], (option) => ({
            value: option[connection.value],
            label: option[connection.label],
          }))
          : connection.options,
      });
    });

    return entities;
  },
);

const DataFilterReducer = createSlice({
  name: 'dataFilter',
  initialState: {
    ...createInitialState(),
    isLoading: true,
  },
  reducers: {
  },
  extraReducers: {
    [getDataFilter.pending]: (state) => {
      state.isLoading = true;
    },
    [getDataFilter.fulfilled]: (state, action) => {
      entityDataFilterAdapter.removeAll(state);
      entityDataFilterAdapter.addMany(state, action.payload);
      state.isLoading = false;
    },
    [getDataFilter.rejected]: (state, { error }) => {
      state.isLoading = false;
      console.error(error);
    },
  },
});

export default DataFilterReducer.reducer;
