import _ from 'lodash';
import DimensionColumnFormatter from '../column-formatters/DimensionColumnFormatter';
import StringColumnFormatter from '../column-formatters/StringColumnFormatter';

class ColumnTypeDetector {
  column;

  constructor(column) {
    this.column = column;
  }

  getFormatter = () => {
    if (_.includes(['select', 'multiselect'], this.column.dataType)) {
      return DimensionColumnFormatter;
    }
    return StringColumnFormatter;
  };

  format = (cell, row, rowIndex) => this.getFormatter()(cell, row, rowIndex, this.column.formatExtraData);
}

export default ColumnTypeDetector;
