import axios from 'axios';
import _ from 'lodash';
import { KTCookie } from '../_metronic/_assets/js/components/cookie';

class QueryService {
  cache = {};

  run = async (query, onUnauthorized = () => {}) => {
    // If we shouldn't cache, just get the results.
    if (!query.cache) {
      console.log(`Running: ${JSON.stringify(query)}`);
      return QueryService._runQuery(query, onUnauthorized);
    }

    // Otherwise, fetch the cache, or add to it.
    if (this.isCached(query)) {
      console.log(`Returning Cache: ${QueryService.getCacheKey(query)}`);
      return this.getCached(query);
    }

    console.log(`Running: ${JSON.stringify(query)}`);
    this.addCache(query, await QueryService._runQuery(query, onUnauthorized));
    return this.getCached(query);
  };

  isCached = (query) => _.has(this.cache, QueryService.getCacheKey(query));

  getCached = (query) => (this.isCached(query) ? this.cache[QueryService.getCacheKey(query)] : null);

  addCache = (query, data) => {
    this.cache[QueryService.getCacheKey(query)] = data;
  };

  static getCacheKey = (query) => JSON.stringify(query);

  static async _runQuery(query, onUnauthorized) {
    try {
      const results = await axios.post(`${window.env.API_URL}/query`, query, { headers: { Authorization: `Bearer ${QueryService._getAuthorization()}` } });
      return results.data.data;
    } catch (e) {
      if (e.response.status === 401) {
        onUnauthorized();
      }
      throw e;
    }
  }

  static _getAuthorization() {
    return KTCookie.getCookie('jwt');
  }
}

export default new QueryService();
