import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isoReportScheme: [],
  loading: false,
  error: null,
};

const isoReportSchemeSlice = createSlice({
  name: 'isoReportScheme',
  initialState,
  reducers: {
    fetchISOReportSchemeStart: (state) => {
      state.loading = true;
    },
    fetchISOReportSchemeSuccess: (state, action) => {
      state.isoReportScheme = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchISOReportSchemeFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchISOReportSchemeStart,
  fetchISOReportSchemeSuccess,
  fetchISOReportSchemeFailure,
} = isoReportSchemeSlice.actions;

export default isoReportSchemeSlice.reducer;
