import React, { useEffect, useState } from 'react';
import {
  Col, Modal, OverlayTrigger, Row, Tooltip, Spinner,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import _, { clone, mapValues } from 'lodash';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { KTCookie } from '../../../_metronic/_assets/js/components/cookie';
import ChangeField from './ChangeField';
import Button from '../../../components/Button';
import FormGroupHeading from '../../../components/FormGroupHeading';
import ModalBodyLoading from '../../../components/modals/ModalBodyLoading';
import { isDataSaving } from '../selectors/DataSelector';
import { getDataMetaKeyValue } from '../selectors/DataMetaSelector';
import { addDataModifiedHidden, saveDataModified } from '../reducers/DataModifiedReducer';

const keyBinding = (
  e,
  onHide = () => {},
  onSubmit = () => {},
) => {
  if (document.activeElement.tagName.toLowerCase() === 'input') {
    // Do nothing
  } else if (e.key === 'Escape') {
    onHide();
  } else if (e.key === 'Enter') {
    onSubmit();
  }
};

const ChangeForm = ({
  id,
  onHide,
  fields,
  fieldGroups,
  onChange = () => {},
  allowCopy = false,
  allowDelete = false,
  saveButtonText = 'Save',
  copyButtonText = 'Copy Subscription',
  history,
}) => {
  const dispatch = useDispatch();
  const columnInitialValues = mapValues(useSelector(getDataMetaKeyValue), 'defaultValue');
  const isSaving = useSelector(isDataSaving);

  const [onSubmit, setOnSubmit] = useState();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    const listener = (e) => keyBinding(e, onHide, onSubmit);
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onHide, onSubmit]);

  // Validation schema
  const schema = Yup.object().shape(Object.assign({}, ..._.map(_.flatMap(fieldGroups), (field) => {
    if (field.required) {
      return { [field.name]: Yup.mixed().required(`The ${field.label} field is required`) };
    }
    return { [field.name]: Yup.mixed() };
  })));

  // Put the initial value of -1 if we have a picklist.
  const initialValues = _.assign(_.clone(columnInitialValues), fields);

  const [isRunningReport, setIsRunningReport] = useState(false);

  const runSubscription = async () => {
    setIsRunningReport(true);

    axios({
      method: 'GET',
      url: `${window.env.API_URL}/report/${id}`,
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${KTCookie.getCookie('jwt')}`,
      },
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
      },
    }).then((response) => {
      console.log(response);
      // Extract filename from content-disposition header
      const filename = response.headers['content-disposition'].match(/filename\s*=\s*"(.+)"/i)[1];
      // Create temporary `a` element
      const link = document.createElement('a');
      // Set href to an object url created from the blob (binary data), set download attribute to the filename
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const href = URL.createObjectURL(blob);
      link.href = href;
      link.setAttribute('download', decodeURIComponent(filename));
      // Append element to document and click to initiate download
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsRunningReport(false);
    });
  };

  const [hasChanged, setHasChanged] = useState(false);

  return (
    <>
      {_.isEmpty(fieldGroups) && <ModalBodyLoading />}
      {!_.isEmpty(fieldGroups) && (
        <Modal.Body className="overlay overlay-block cursor-default">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
              setHasSubmitted(true);
              dispatch(addDataModifiedHidden(values));
              dispatch(saveDataModified()).then(() => onHide());
            }}
          >
            {({
              handleSubmit, setFieldValue, getFieldProps, errors, touched, dirty,
            }) => {
              if (_.isUndefined(onSubmit)) {
                setOnSubmit(() => handleSubmit);
              }

              if (hasChanged !== dirty) {
                setHasChanged(dirty);
              }

              return _.map(fieldGroups, (group, groupId) => (
                <div key={groupId} className="form form-label-right">
                  <FormGroupHeading title={_.head(group).groupName} />
                  <Row className="form-group">
                    {_.map(group, (field) => (
                      <Col key={field.name} xs={field.width} className="mb-4">
                        <ChangeField
                          {...getFieldProps(field.name)}
                          field={field}
                          error={errors[field.name]}
                          touched={touched[field.name]}
                          onChange={(value) => {
                            setFieldValue(field.name, value);
                            onChange(field.name, value);
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              ));
            }}
          </Formik>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div className="mr-auto">
          {allowCopy && (
            <Button
              disabled={isSaving}
              onClick={() => history.push(`/subscriptions/${id}/copy`)}
              variant="primary"
              className="mr-2"
            >
              {copyButtonText}
            </Button>
          )}
          {allowDelete && (
            <Button
              disabled={isSaving}
              className="mr-2"
              onClick={() => {
                const fieldsChanged = clone(fields);
                fieldsChanged.Disabled = 1;
                dispatch(addDataModifiedHidden(fieldsChanged));
                dispatch(saveDataModified()).then(() => onHide());
              }}
              variant="danger"
            >
              Delete
            </Button>
          )}

          <OverlayTrigger
            placement="top"
            overlay={
              hasChanged ? <Tooltip>You must save or discard your changes first</Tooltip> : <span />
            }
          >
            <span className="d-inline-block">
              <Button
                onClick={runSubscription}
                variant="primary"
                className="mr-2"
                disabled={hasChanged}
                style={{ pointerEvents: hasChanged ? 'none' : '' }}
              >
                Run Now
              </Button>
            </span>
          </OverlayTrigger>

          <Button
            onClick={() => {}}
            variant="warning"
          >
            Email Now
          </Button>
        </div>
        <Button
          disabled={isSaving}
          onClick={onHide}
          variant="light"
        >
          Cancel
        </Button>
        <Button
          showSpinner={isSaving && hasSubmitted}
          disabled={isSaving}
          onClick={onSubmit}
          variant="primary"
        >
          {isSaving && hasSubmitted ? 'Saving...' : saveButtonText}
        </Button>

        { isRunningReport && (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              backgroundColor: '#00000085',
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              margin: '0',
              borderRadius: '0.42rem',
            }}
          >
            <Spinner className="mb-2" animation="border" variant="white" />
            <span className="text-white">Please wait while your report is generated</span>
          </div>
        )}
      </Modal.Footer>
    </>
  );
};

export default withRouter(ChangeForm);
