import { createSlice } from '@reduxjs/toolkit';
import { KTCookie } from '../../_metronic/_assets/js/components/cookie';

const LayoutReducer = createSlice({
  name: 'list',
  initialState: {
    menuMinimized: (KTCookie.getCookie('menu-minimized') ?? 'false') === 'true',
  },
  reducers: {
    setLayoutMenuMinimized: (state, action) => {
      state.menuMinimized = action.payload;
      KTCookie.setCookie('menu-minimized', action.payload);
    },
  },
});

export default LayoutReducer.reducer;
export const { setLayoutMenuMinimized } = LayoutReducer.actions;
