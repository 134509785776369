import React from 'react';
import _, { map, sortBy } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';
import ListGroupCheck from './ListGroupCheck';
import IconButton from '../IconButton';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...isDragging ? {
    border: 'solid 1px #1485ff',
    borderRadius: '0.42rem',
  } : {},
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#e1e6f5' : '#e9ecef',
  overflow: 'hidden',
  flex: 1,
});

const reorderItems = (items) => map(items, (item, index) => ({
  ...item,
  sortOrderNew: index,
}));

const ListGroupDualBox = ({
  box1Title = '',
  box2Title = '',
  box1Items: box1ItemsProp = [],
  box2Items: box2ItemsProp = [],
  onChange = () => {},
}) => {
  const box1Items = sortBy(box1ItemsProp, (item) => item.sortOrderNew ?? item.sortOrder);
  const box2Items = sortBy(box2ItemsProp, (item) => item.sortOrderNew ?? item.sortOrder);

  const onUncheck = (column) => {
    onChange(
      [...box1Items, column],
      _.pull([...box2Items], column),
    );
  };

  const onCheck = (column) => {
    onChange(
      _.pull([...box1Items], column),
      [...box2Items, column],
    );
  };

  const onUncheckAll = () => {
    onChange([...box1Items, ...box2Items], []);
  };

  const onCheckAll = () => {
    onChange([], [...box1Items, ...box2Items]);
  };

  const onDragEnd = (result) => { // dropped outside the list
    if (!result.destination) {
      return;
    }

    const box1ItemsNew = [...box1Items];
    const box2ItemsNew = [...box2Items];

    // Remove item from original list.
    const [removedItem] = result.source.droppableId === 'box-1-items'
      ? box1ItemsNew.splice(result.source.index, 1) : box2ItemsNew.splice(result.source.index, 1);

    // Add the item to the new list.
    if (result.destination.droppableId === 'box-1-items') {
      box1ItemsNew.splice(result.destination.index, 0, removedItem);
    } else {
      box2ItemsNew.splice(result.destination.index, 0, removedItem);
    }
    onChange(reorderItems(box1ItemsNew), reorderItems(box2ItemsNew));
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 className="ml-1 mb-4 flex-grow-1">
          {box1Title}
          <span className="text-muted small">{` (${_.size(box1Items)})`}</span>
        </h4>
        <div style={{ flex: 0.25 }} />
        <h4 className="ml-1 mb-4 flex-grow-1">
          {box2Title}
          <span className="text-muted small">{` (${_.size(box2Items)})`}</span>
        </h4>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="box-1-items">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="mr-4"
              >
                {box1Items.map((column, index) => (
                  <Draggable
                    key={column.name}
                    draggableId={column.name}
                    index={index}
                  >
                    {(dragProps, dragSnapshot) => (
                      <ListGroupCheck
                        key={column.name}
                        checked
                        onClick={() => onCheck(column)}
                        ref={dragProps.innerRef}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...dragProps.draggableProps}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...dragProps.dragHandleProps}
                        style={getItemStyle(
                          dragSnapshot.isDragging,
                          dragProps.draggableProps.style,
                          index,
                        )}
                      >
                        {column.label}
                      </ListGroupCheck>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div style={{
            flex: 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          >
            <span>
              <IconButton
                btnColor="ghost"
                iconColor="primary"
                iconClass="fad fa-chevron-double-right"
                onClick={() => onCheckAll()}
              />
            </span>
            <span>
              <IconButton
                btnColor="ghost"
                iconColor="primary"
                btnClass="mt-3"
                iconClass="fad fa-chevron-double-left"
                onClick={() => onUncheckAll()}
              />
            </span>
          </div>
          <Droppable droppableId="box-2-items">
            {(provided, snapshot) => (
              <ListGroup
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="ml-4"
              >
                {box2Items.map((column, index) => (
                  <Draggable
                    key={column.name}
                    draggableId={column.name}
                    index={index}
                  >
                    {(dragProps, dragSnapshot) => (
                      <ListGroupCheck
                        key={column.name}
                        checked
                        onClick={() => onUncheck(column)}
                        ref={dragProps.innerRef}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...dragProps.draggableProps}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...dragProps.dragHandleProps}
                        style={getItemStyle(
                          dragSnapshot.isDragging,
                          dragProps.draggableProps.style,
                          index,
                        )}
                      >
                        {column.label}
                      </ListGroupCheck>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ListGroup>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default ListGroupDualBox;
