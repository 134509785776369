import React from 'react';

const FormGroupHeading = ({
  title = '',
}) => (
  <div role="alert" className="fade alert alert-light show" style={{ backgroundColor: '#dbecff' }}>
    <h6 className="text-dark font-weight-bold mb-0">{title}</h6>
  </div>
);

export default FormGroupHeading;
