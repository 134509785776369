import React, { Suspense, useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  fetchGlobalDimensionsStart,
  fetchISOReportSchemeStart,
  fetchISOReports,
  fetchSSRSReportsStart,
  fetchConfigStart
} from 'src/redux';
import { LayoutSplashScreen, ContentRoute } from './_metronic/layout';
import { DashboardPage } from './_metronic/_partials';
import SubscriptionsPage from './pages/subscriptions';
import ReportsPage from './pages/reports';

export default function BasePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGlobalDimensionsStart());
    dispatch(fetchISOReportSchemeStart());
    dispatch(fetchISOReports());
    dispatch(fetchSSRSReportsStart());
    dispatch(fetchConfigStart());
  }, [dispatch]);

  const startingPage = '/subscriptions';
  // const startingPage = KTCookie.getCookie('favourite') || '/dashboard';

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to={startingPage} />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/subscriptions" component={SubscriptionsPage} />
        <Route path="/reports" component={ReportsPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
