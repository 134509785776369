import React from "react";
import {PaginationLinks} from "./PaginationLinks";
import {PaginationToolbar} from "./PaginationToolbar";
import {PaginationTotalStandalone} from "react-bootstrap-table2-paginator";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      <div className="d-flex justify-content-end" style={{ marginTop: '-2.7rem', marginBottom: '0.7rem' }}>
        <PaginationTotalStandalone className="text-muted" {...paginationProps} />
      </div>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationLinks paginationProps={paginationProps} />
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
      </div>
    </>
  );
}
