import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { isEqual } from 'lodash';

const useSelectorConditionMemo = (func, initialValue, condition) => {
  const storedRef = useRef(initialValue);
  const storedMemo = useRef(initialValue);

  return useSelector((state) => {
    const funcValue = func(state);

    if (!condition) {
      storedMemo.current = funcValue;
      return storedRef.current;
    }

    if (isEqual(storedMemo.current, funcValue)) {
      return storedRef.current;
    }

    storedRef.current = funcValue;
    storedMemo.current = funcValue;
    return storedRef.current;
  });
};

export default useSelectorConditionMemo;
