import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isFiltersInitialised } from '../selectors/FilterSelector';
import { getDataConnection } from '../selectors/ListSelector';
import { getData } from '../reducers/DataReducer';
import ToastSaveResult from '../toast/ToastSaveResult';
import { isDataMetaInitialised } from '../selectors/DataMetaSelector';
import useDeepEffect from '../hooks/useDeepEffect';
import { replaceConnection } from '../selectors/DataSelector';

const DataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isInitialised = useSelector(isFiltersInitialised && isDataMetaInitialised);
  const connection = useSelector((state) => replaceConnection(state, getDataConnection(state)));

  useDeepEffect(() => {
    if (isInitialised) {
      dispatch(getData(connection));
    }
  }, [dispatch, isInitialised, connection]);

  return (
    <>
      {children}
      <ToastSaveResult />
    </>
  );
};

export default DataProvider;
