import React, { useRef } from 'react';
import paginationFactory, { PaginationProvider as PaginationProviderBootstrap } from 'react-bootstrap-table2-paginator';
import { isUndefined, throttle } from 'lodash';
import { Pagination } from '../../../_metronic/_partials/controls';

/**
 * Monkey patch the existing pagination provider to fix issues where external data changes occur,
 * or allow events to propagate to the parent.
 */
const PaginationProvider = ({
  pagination, baseProps, children, onPageChange = () => {},
}) => {
  const dataSizeRef = useRef();
  const onSizePerPageChangeRef = useRef();
  const onFilterChangeRef = useRef();

  const onSizePerPageChange = (currPage, currSizePerPage) => {
    onPageChange(currPage);
    onSizePerPageChangeRef.current(currPage, currSizePerPage);
  };

  const onFilterChange = throttle((val) => {
    dataSizeRef.current = val;
    onFilterChangeRef.current(val);
  }, 100);

  const replaceProps = (props) => {
    const propsReturn = { ...props };
    propsReturn.dataSize = dataSizeRef.current ?? props.dataSize;
    propsReturn.pagination.options.dataSize = dataSizeRef.current ?? props.dataSize;

    // Hook into the page change function, to provide propagation.
    if (isUndefined(onSizePerPageChangeRef.current)) {
      onSizePerPageChangeRef.current = props.onSizePerPageChange;
    }
    propsReturn.onSizePerPageChange = onSizePerPageChange;

    // Hook into the filter change, to get the correct page size.
    if (isUndefined(onFilterChangeRef.current)) {
      onFilterChangeRef.current = props.dataChangeListener._events.filterChanged;
    }
    propsReturn.dataChangeListener._events.filterChanged = onFilterChange;

    // Fix issue where we have new data that has less pages than what are currently on.
    if (isUndefined(props.page) || props.page > Math.ceil((dataSizeRef.current ?? propsReturn.dataSize) / props.sizePerPage)) {
      propsReturn.pagination.options.page = 1;
      propsReturn.page = 1;
      setTimeout(() => props.onPageChange(1));
    }
    return propsReturn;
  };

  return (
    <PaginationProviderBootstrap pagination={paginationFactory(pagination)}>
      {({ paginationProps, paginationTableProps }) => {
        const props = replaceProps({ ...paginationTableProps, ...baseProps, ...paginationProps });
        dataSizeRef.current = undefined;

        return (
          <Pagination paginationProps={props}>
            {children({ paginationProps: props })}
          </Pagination>
        );
      }}
    </PaginationProviderBootstrap>
  );
};

export default PaginationProvider;
