import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reportConfig: [],
  subscriptionConfig: [],
  loading: false,
  error: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    fetchConfigStart: (state) => {
      state.loading = true;
    },
    fetchConfigSuccess: (state, action) => {
      state.reportConfig = action.payload.reportConfig;
      state.subscriptionConfig = action.payload.subscriptionConfig;
      state.loading = false;
      state.error = null;
    },
    fetchConfigFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchConfigStart,
  fetchConfigSuccess,
  fetchConfigFailure,
} = configSlice.actions;

export default configSlice.reducer;
