/* eslint-disable */
import { Grid, Button } from '@material-ui/core';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';

const CustomToolbar = ({ onClick }) => {
  return (
    <GridToolbarContainer>
      <Grid container item xs>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <div className='flex-grow-1'/>
        <Button
          color='primary'
          size='small'
          startIcon={<i className="fa fa-plus" />}
          onClick={onClick}
        >
          New Report
        </Button>
      </Grid>
    </GridToolbarContainer>
  );
}

export default CustomToolbar;