/* eslint-disable */
import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const CustomStepper = ({ activeStep, onStepChange, steps, stepsContent }) => {
  const handleStepChange = (step) => {
    if (onStepChange) {
      onStepChange(step);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index} onClick={() => (index < activeStep ? handleStepChange(index) : null)}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, mb: 1 }}>
        {stepsContent.map((content, index) => (
          <div key={index} style={{ display: index === activeStep ? "block" : "none" }}>
            {content}
          </div>
        ))}
      </Box>
    </Box>
  );
}

export default CustomStepper;