import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getFilterOptionsWithCounts } from '../selectors/FilterSelector';
import { getData, getDataModifiedFiltered, isDataLoading } from '../selectors/DataSelector';
import { isDataMetaLoading } from '../selectors/DataMetaSelector';
import { setFilterState } from '../reducers/FilterReducer';
import { getEditType } from '../../../const';
import { clearDataModified } from '../reducers/DataModifiedReducer';
import PageService from '../../../services/PageService';
import useSelectorConditionMemo from '../hooks/useSelectorConditionMemo';

const ListFilter = ({ filter }) => {
  const filterValue = `${filter.state ?? filter.initialState ?? ''}`;

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => isDataLoading(state) || isDataMetaLoading(state)) && !PageService.isModalOpen();
  const data = useSelectorConditionMemo(filter.labelIncludeCount ? (filterValue === '-1' ? getDataModifiedFiltered : getData) : () => {}, [], !PageService.isModalOpen());
  const filterOptions = useMemo(() => getFilterOptionsWithCounts(filter, data), [filter, data]);

  const onChangeDispatch = (value) => {
    dispatch(clearDataModified());
    dispatch(setFilterState({ name: filter.name, value }));
  };
  const onChange = debounce(onChangeDispatch, 300);
  const TagName = getEditType(filter.type);

  return (
    <div className="form form-label-right">
      <div className="form-group">
        <TagName
          name={`field${filter.name}`}
          options={filterOptions}
          initialValue={filterValue}
          onChange={onChange}
          helpText={filter.helpText}
          isLoading={filter.showLoading && isLoading}
          clearValue={filter.isClearable ? filter.initialState : undefined}
          showTooltip
        />
      </div>
    </div>
  );
};

export default ListFilter;
