import React from 'react';

const ChangedColumnFormatter = ({
  row,
  className = '',
  ...props
}) => (
  <div className={`td-wrapper ${className}${row._modified ? ' alert-warning' : ''}`}>
    {props.children}
  </div>
);

export default ChangedColumnFormatter;
