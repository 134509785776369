/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { AuthInit } from './_modules/Auth';
import Routes from './routes';
import { LayoutSplashScreen, MaterialThemeProvider } from './_metronic/layout';
import { I18nProvider } from './_metronic/i18n/I18nProvider';

const getBootstrapPrimaryColor = () => {
  const computedStyles = getComputedStyle(document.documentElement);
  return computedStyles.getPropertyValue('--primary').trim();
};

const createCustomMuiTheme = () => {
  const bootstrapPrimaryColor = getBootstrapPrimaryColor();
  return createMuiTheme({
    palette: {
      primary: {
        main: bootstrapPrimaryColor,
      },
    },
  });
};

export default function App({ store, persistor, basename }) {
  const customMuiTheme = createCustomMuiTheme();

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <HashRouter basename={basename}>
            {/* This library only returns the location that has been active before the recent location change in the current window lifetime. */}
            <MaterialThemeProvider>
              {/* Setup MUI theme */}
              <ThemeProvider theme={customMuiTheme}>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <AuthInit>
                    <Routes />
                  </AuthInit>
                </I18nProvider>
              </ThemeProvider>
            </MaterialThemeProvider>
          </HashRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
