import React from 'react';
import ChangedColumnFormatter from './ChangedColumnFormatter';
import IconButton from '../IconButton';

const ActionsColumnFormatter = (cell, row) => {
  const { Active: active, id } = row;

  return (
    <ChangedColumnFormatter row={row} className="text-nowrap">
      <IconButton
        btnColor="light"
        btnClass="btn-sm mr-3 ml-auto"
        iconColor="primary"
        iconClass="fad fa-pencil"
        iconSize="sm"
        href={`/subscriptions/${id}/edit`}
        tooltip="Edit subscription"
      />

      {active === 1
      && (
        <IconButton
          btnColor="light"
          btnClass="btn-sm mr-auto"
          iconColor="success"
          iconColorHover="danger"
          iconClass="fa fa-check"
          iconClassHover="fa fa-times"
          iconSize="md"
          href={`/subscriptions/${id}/disable`}
          tooltip="Deactivate Subscription"
        />
      )}
      {active !== 1
      && (
        <IconButton
          btnColor="light"
          btnClass="btn-sm mr-auto"
          iconColor="danger"
          iconColorHover="success"
          iconClass="fa fa-times"
          iconClassHover="fa fa-check"
          iconSize="md"
          href={`/subscriptions/${id}/enable`}
          tooltip="Activate subscription"
        />
      )}
    </ChangedColumnFormatter>
  );
};

export default ActionsColumnFormatter;
