import React, { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ToastModal = ({
  show, onHide, message, type, duration = 3000,
}) => {
  useEffect(() => {
    let interval = null;

    if (show) {
      interval = setInterval(() => {
        clearInterval(interval);
        onHide(false);
      }, duration);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [duration, onHide, show]);

  if (!show) {
    return null;
  }
  return (
    <Snackbar
      open={show}
      onClose={() => onHide()}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={() => onHide()} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastModal;
