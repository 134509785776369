import _ from 'lodash';
import DimensionColumnCSVFormatter from '../column-formatters/DimensionColumnCSVFormatter';
import StringColumnCSVFormatter from '../column-formatters/StringColumnCSVFormatter';

class ColumnTypeCSVDetector {
  column;

  constructor(column) {
    this.column = column;
  }

  getFormatter = () => {
    if (_.includes(['select', 'multiselect'], this.column.dataType)) {
      return DimensionColumnCSVFormatter;
    }
    return StringColumnCSVFormatter;
  };

  format = (cell, row, rowIndex) => this.getFormatter()(cell, row, rowIndex, this.column.formatExtraData);
}

export default ColumnTypeCSVDetector;
