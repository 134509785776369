import * as Yup from 'yup';
import { ControlTypes } from './controlTypes';

export const parseDDRSValues = (values, groups) => Object.values(groups).reduce((acc, group) => {
  const id = group.find((column) => column.ControlTypeID === ControlTypes.DDRS_ID);
  const processorder = group.find((column) => column.ControlTypeID === ControlTypes.text);

  if (id && values[id.SchemaColumnName]) {
    // eslint-disable-next-line no-param-reassign
    acc[processorder.SchemaColumnName] = values[processorder.SchemaColumnName];
  }

  return acc;
}, {});

export const generateDDRSValidationSchema = (columns) => {
  const ddrsIdColumns = columns.filter((column) => column.ControlTypeID === ControlTypes.DDRS_ID);
  const processOrderColumns = columns.filter((column) => column.ControlTypeID === ControlTypes.text);

  return Yup.lazy((values) => {
    const shape = processOrderColumns.reduce((acc, column) => {
      const processOrderID = column.SchemaColumnName;
      const ddrsIdColumn = ddrsIdColumns.find((idCol) => idCol.DDRSGroupID === column.DDRSGroupID);
      const ddrsIdValue = values[ddrsIdColumn.SchemaColumnName];

      // eslint-disable-next-line no-param-reassign
      acc[processOrderID] = ddrsIdValue
        ? Yup.number()
          .min(0, 'Process order must be a non-negative integer')
          .integer('Process order must be an integer')
          .required('Process order is required')
        : Yup.mixed().notRequired(); // Do not apply validation if DDRS ID is null or undefined

      return acc;
    }, {});

    return Yup.object().shape(shape);
  });
};
