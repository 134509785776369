/* eslint no-use-before-define: 0 */
import { createSelector } from '@reduxjs/toolkit';
import _, {
  chain,
  keyBy, map, mapValues,
} from 'lodash';
import { entityDataFilterAdapter } from '../reducers/DataFilterReducer';
import { entityFilterAdapter } from '../reducers/FilterReducer';
import { replaceMergeFields } from '../util';

/**
 * Filter config selectors
 */
export const {
  selectById: getFilterByNameConfig,
  selectEntities: getFiltersKeyValueConfig,
  selectAll: getFilterConfig,
} = entityFilterAdapter.getSelectors((state) => state.filter);

/**
 * Filter data selectors (as it relates to data returned by the source)
 */
export const {
  selectById: getFilterDataByName,
  selectEntities: getFilterDataKeyValue,
  selectAll: getFilterData,
} = entityDataFilterAdapter.getSelectors((state) => state.dataFilter);

/**
 * Returns a list of filters, and its various options (based on the data provided)
 */
export const getFilters = createSelector(
  [getFilterConfig, getFilterDataKeyValue],
  (filters, filterData) => _.map(filters, (filter) => ({
    ...filter,
    options: filterData[filter.name]?.options,
    optionsKV: mapValues(keyBy(filterData[filter.name]?.options, 'value'), 'label'),
  })),
);

export const getFiltersTop = (state) => _.filter(getFilters(state), (filter) => filter.position === 'top');
export const getFiltersDropdown = (state) => _.filter(getFilters(state), (filter) => filter.position === 'dropdown');

export const isFiltersInitialised = (state) => state.filter.isInitialised;
export const isFiltersChanged = (state) => state.filter.isChanged;
export const isFilterPreloadSelected = (state) => _.filter(getFilterConfig(state), (filter) => filter.preloadMetaData && filter.state !== filter.initialState).length > 0;

export const getFiltersKeyValue = createSelector([getFilters], (filters) => _.keyBy(filters, 'name'));
export const getFilterByName = (state, name) => getFiltersKeyValue(state)[name];

export const getFiltersWithRemote = createSelector([getFilters], (filters) => _.pickBy(filters, { isRemoteFilter: true }));
export const getFiltersWithConnections = createSelector([getFilters], (filters) => _.pickBy(filters, (filter) => !_.isNull(filter.connection.datasource)));
export const getFilterConnectionsKeyValue = createSelector([getFiltersKeyValue], (filters) => _.mapValues(filters, 'connection'));

export const getObjectFilterReplacement = (state, object) => JSON.parse(replaceFilterMergeFields(state, JSON.stringify(object)));

/**
 * Returns a list of user settings that we want stored and to persist across page refreshes.
 */
export const getFilterPreferences = (state) => ({
  values: getFilterValuesKeyValue(state),
});

/**
 * Returns a list of filter values and labels as a KV pair
 */
export const getFilterValueLabels = createSelector(
  [getFilters],
  (filters) => Object.assign({}, ..._.map(filters, (filter) => ({
    [filter.name]: filter.state ?? filter.initialState,
  }))),
);

/**
 * Replace a string with all the filter values
 */
export const replaceFilterMergeFields = (state, string) => {
  const filterValueLabels = getFilterValueLabels(state);
  const replacedMergeFields = replaceMergeFields(filterValueLabels, string);
  return replacedMergeFields;
};

/**
 * Replace a connection with statement with all the filter values
 */
export const replaceConnection = (state, connection) => ({
  ...connection,
  statement: replaceFilterMergeFields(state, connection.statement),
});

/**
 * Returns a list of filters as a KV pair
 */
export const getFilterValues = createSelector(
  [getFilters],
  (filters) => Object.assign({}, ..._.map(filters, (filter) => ({
    [filter.name]: filter.state || filter.initialState,
  }))),
);

/**
 * Returns a list of filters as a name - value pair
 */
export const getFilterValuesNameValue = createSelector(
  [getFilters],
  (filters) => _.map(filters, (filter) => ({
    name: filter.name,
    value: filter.state || filter.initialState,
  })),
);

/**
 * Returns a list of filters values as a key value pair
 */
export const getFilterValuesKeyValue = createSelector(
  [getFilters],
  (filters) => Object.assign({}, ..._.map(filters, (filter) => ({
    [filter.name]: filter.state || filter.initialState,
  }))),
);

/**
 * Accepts a filter, and data as an input, and provides a list of options
 * that have the quantities next to them.
 *
 * This is useful, as we can provide any type of data we want.
 * E.g. data modified, data filtered, raw data ..etc.
 */
export const getFilterOptionsWithCounts = (filter, data) => map(filter.options, (option) => {
  const { value, label } = option;

  if (filter.labelIncludeCount && `${option.value}` !== '-1') {
    return {
      value,
      label: `${label} (${_.filter(data, { [filter.connection.value]: value }).length})`,
    };
  }
  if (filter.labelIncludeCount) {
    return {
      value,
      label: `${label} (${_.size(data)})`,
    };
  }
  return { value, label };
});

export const getFilterOptionLabelString = (filter) => chain(filter.options)
  .filter((option) => option.value.toString() === (filter.state ?? filter.initialState).toString())
  .map((option) => option.label)
  .join(', ')
  .value();

export const getFilterOptionValueString = (filter) => chain(filter.options)
  .filter((option) => option.value.toString() === (filter.state ?? filter.initialState).toString())
  .map((option) => option.value)
  .join(', ')
  .value();

export const getFilterNotOptionLabelString = (filter) => chain(filter.options)
  .filter((option) => option.value.toString() !== (filter.state ?? filter.initialState).toString())
  .map((option) => option.label)
  .join(', ')
  .value();

export const getFilterNotOptionValueString = (filter) => chain(filter.options)
  .filter((option) => option.value.toString() !== (filter.state ?? filter.initialState).toString())
  .map((option) => option.value)
  .join(', ')
  .value();
