import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dimensions: [],
  loading: false,
  error: null,
};

const globalDimensionsSlice = createSlice({
  name: 'globalDimensions',
  initialState,
  reducers: {
    fetchGlobalDimensionsStart: (state) => {
      state.loading = true;
    },
    fetchGlobalDimensionsSuccess: (state, action) => {
      state.dimensions = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchGlobalDimensionsFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchGlobalDimensionsStart,
  fetchGlobalDimensionsSuccess,
  fetchGlobalDimensionsFailure,
} = globalDimensionsSlice.actions;

export default globalDimensionsSlice.reducer;
