import React from 'react';
import Text from './Text';

const Search = ({ ...props }) => (
  <Text
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
    iconClass="fa fa-search"
  />
);

export default Search;
