import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { isBoolean, map, mapValues } from 'lodash';
import config from '../config';
import QueryService from '../../../services/QueryService';

export const entityDataAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

export const getData = createAsyncThunk(
  'data/get',
  async (connection) => QueryService.run(connection),
);

const DataReducer = createSlice({
  name: 'data',
  initialState: {
    ...entityDataAdapter.getInitialState(),
    isLoading: true,
  },
  reducers: {
  },
  extraReducers: {
    [getData.pending]: (state) => {
      state.isLoading = true;
    },
    [getData.fulfilled]: (state, action) => {
      entityDataAdapter.removeAll(state);
      entityDataAdapter.addMany(state, map(action.payload, (row) => ({
        // Convert true/false to 1/0 to match with what goes back into the DW
        ...mapValues(row, (value) => (isBoolean(value) ? 0 + value : value)),
        id: row[config.list.index],
      })));
      state.isLoading = false;
    },
    [getData.rejected]: (state, { error }) => {
      state.isLoading = false;
      console.error(error);
    },
  },
});

export default DataReducer.reducer;
export const { setData, setIsLoading } = DataReducer.actions;
