import { put, takeLatest, call } from 'redux-saga/effects';
import QueryService from 'src/services/QueryService';
import {
  fetchSSRSReportsStart,
  fetchSSRSReportsSuccess,
  fetchSSRSReportsFailure,
} from './ssrsReportsSlice';

function* fetchSSRSReports() {
  try {
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_GetSSRSReports',
      cache: false,
    };

    const result = yield call(QueryService.run, query);

    yield put(fetchSSRSReportsSuccess(result));
  } catch (error) {
    yield put(fetchSSRSReportsFailure(error.message));
  }
}

export default function* watchSSRSReports() {
  yield takeLatest(fetchSSRSReportsStart.type, fetchSSRSReports);
}
