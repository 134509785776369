import React, { useState } from 'react';
import { ListGroupItem } from 'react-bootstrap';

const ListGroupCheck = ({
  onClick = () => {},
  checked = false,
  children = '',
  style = {},
  className = '',
  ...props
}, ref) => {
  const [hover, setHover] = useState(false);

  return (
    <ListGroupItem
      className={`bg-hover-gray-100 d-flex justify-content-between cursor-pointer user-select-none ${className}`}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
      style={style}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {children}
      <div>
        <span className={!(checked !== hover) ? 'd-none' : ''}>
          <i className="fa fa-check text-success" />
        </span>
        <span className={(checked !== hover) ? 'd-none' : ''}>
          <i className="fa fa-times text-danger" />
        </span>
      </div>
    </ListGroupItem>
  );
};

export default React.forwardRef(ListGroupCheck);
