import { put, takeLatest, call, select } from 'redux-saga/effects';
import QueryService from '../../services/QueryService';
import {
  fetchISOReports,
  fetchISOReportsSuccess,
  fetchISOReportsFailure,
  saveISOReport,
  saveISOReportSuccess,
  saveISOReportFailure,
  fetchISOReportByIdFailure,
  fetchISOReportByIdSuccess,
  fetchISOReportById,
} from './isoReportsSlice';

function* fetchISOReportsSaga() {
  try {
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_GetISOReports @Data_Scheme = 0',
      cache: false,
    };

    const result = yield call(QueryService.run, query);

    yield put(fetchISOReportsSuccess(result));
  } catch (error) {
    yield put(fetchISOReportsFailure(error.message));
  }
}

function* saveISOReportSaga(action) {
  try {
    const formData = action.payload;
    const fieldConfig = yield select((state) => state.config.reportConfig);
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_ManageISOReport ',
      cache: true,
    };

    if (formData.ReportSelection) {
      formData.ReportID = formData.ReportSelection;
    }

    query.statement += fieldConfig.map((field) => {
      let value;
      if (Object.prototype.hasOwnProperty.call(formData, field.source)) {
        value = formData[field.source];
      } else if (!formData.ReportDBID && Object.prototype.hasOwnProperty.call(field, 'defaultValue')) {
        value = field.defaultValue;
      }

      if (value === null || value === undefined) {
        return undefined;
      }

      switch (field.type) {
        case 'boolean':
          return `@${field.name}=${(value === true || value === 1 || value === '1') ? '1' : '0'}`;
        case 'integer':
          return `@${field.name}=${Number(value)}`;
        default:
          return `@${field.name}='${value.replace(/'/g, '\'\'')}'`;
          // return (value === null || value === undefined) ? `@${field.name}=${value}` : `@${field.name}='${value.replace(/'/g, '\'\'')}'`;
      }
    }).filter(Boolean).join(', ');

    const result = yield call(QueryService.run, query);

    // Check result is what we expect
    if (result && Array.isArray(result) && result.length > 0 && result[0].ReportID) {
      yield put(saveISOReportSuccess(result[0]));
    } else {
      throw new Error('Invalid report data received');
    }
  } catch (error) {
    yield put(saveISOReportFailure(error.message));
  }
}

function* fetchISOReportByIdSaga(action) {
  try {
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: `EXEC ISO.RPS_GetISOReport @pReportID = '${action.payload}'`,
      cache: false,
    };

    const result = yield call(QueryService.run, query);

    // Check result is what we expect
    if (result && Array.isArray(result) && result.length > 0 && result[0].ReportID) {
      yield put(fetchISOReportByIdSuccess(result[0]));
    } else {
      throw new Error('Invalid report data received');
    }
  } catch (error) {
    yield put(fetchISOReportByIdFailure(error.message));
  }
}

export default function* watchISOReports() {
  yield takeLatest(fetchISOReports.type, fetchISOReportsSaga);
  yield takeLatest(saveISOReport.type, saveISOReportSaga);
  yield takeLatest(fetchISOReportById.type, fetchISOReportByIdSaga);
}
