import { createSlice } from '@reduxjs/toolkit';
import config from '../config';

const CopyReducer = createSlice({
  name: 'copy',
  initialState: config.copy,
  reducers: {
    setCopy: (state, action) => {
      state.name = action.payload.name;
    },
  },
});

export default CopyReducer.reducer;
export const { setCopy } = CopyReducer.actions;
