import React from 'react';
import ChangedColumnFormatter from './ChangedColumnFormatter';

// eslint-disable-next-line default-param-last
const StringColumnFormatter = (cell = '', row) => (
  <ChangedColumnFormatter row={row}>
    {cell}
  </ChangedColumnFormatter>
);

export default StringColumnFormatter;
