import React from 'react';
import _ from 'lodash';
import Select from './Select';

const SelectMulti = ({ ...props }) => (
  <Select
    isMulti
    clearValue={() => _.find(props.options, (option) => _.toString(option.value) === '-1')}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

export default SelectMulti;
