import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import SVG from 'react-inlinesvg';

const IconButton = ({
  btnColor = '',
  btnColorHover = btnColor,
  btnClass = '',
  iconColor = '',
  iconColorHover = iconColor,
  iconClass,
  iconClassHover = iconClass,
  iconSVG,
  iconSVGHover = iconSVG,
  iconSize = '',
  tooltip,
  onClick = () => {},
  href = '#',
}, ref) => {
  const [id] = useState(_.uniqueId('tooltip-'));
  const [hover, setHover] = useState(false);
  const [fullRender, setFullRender] = useState(false);

  useEffect(() => {
    let timer = null;
    if (tooltip) {
      timer = setTimeout(() => setFullRender(true), 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [tooltip]);

  const iconButton = (
    <Link
      className={`btn btn-icon btn-${hover ? btnColorHover : btnColor} ${btnClass}`}
      to={href}
      ref={ref}
      onClick={(e) => {
        if (href === '#') {
          e.preventDefault();
        }
        e.currentTarget.blur();
        onClick(e);
      }}
      onMouseEnter={() => {
        if (iconClassHover !== iconClass || btnColor !== btnColorHover) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (iconClassHover !== iconClass || btnColor !== btnColorHover) {
          setHover(false);
        }
      }}
    >
      <span className={`svg-icon svg-icon-${iconSize} svg-icon-${iconColor} ${hover ? 'd-none' : ''}`}>
        {iconClass && <i className={iconClass} />}
        {iconSVG && <SVG src={iconSVG} />}
      </span>
      <span className={`svg-icon svg-icon-${iconSize} svg-icon-${iconColorHover} ${!hover ? 'd-none' : ''}`}>
        {iconClassHover && <i className={iconClassHover} />}
        {iconSVGHover && <SVG src={iconSVGHover} />}
      </span>
    </Link>
  );

  if (fullRender && tooltip) {
    return (
      <OverlayTrigger overlay={<Tooltip id={id}>{tooltip}</Tooltip>}>
        {iconButton}
      </OverlayTrigger>
    );
  }
  return iconButton;
};

export default React.forwardRef(IconButton);
