import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';
import config from '../config';
import { entityDataFilterAdapter } from './DataFilterReducer';

export const entityExportAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
  sortComparer: (a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0),
});

const createInitialState = () => entityDataFilterAdapter.addMany(
  entityDataFilterAdapter.getInitialState(),
  map(config.export, (entity, sortOrder) => ({
    ...entity,
    sortOrder,
    fileName: entity.fileName.replace(/{date}/, new Date().toISOString().slice(0, 10)),
  })),
);

const ExportReducer = createSlice({
  name: 'export',
  initialState: createInitialState(),
  reducers: {
    setExports: (state, action) => entityExportAdapter.addMany(state, action),
  },
});

export default ExportReducer.reducer;
export const { setExports } = ExportReducer.actions;
