import React, {useMemo} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../../_helpers";
import PageService from "../../../../../services/PageService";

export function AsideMenu({disableScroll}) {
  const backgroundImageUrl = toAbsoluteUrl("/media/svg/patterns/aare.svg");
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu mt-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            transform: 'scaleX(-1)',
            width: '100%',
            height: '300px',
            backgroundImage: `url("${backgroundImageUrl}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'bottom',
            backgroundSize: 'cover',
            opacity: 0.8,
          }}>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: '0.6rem',
            fontSize: '1.4rem',
            color: 'white',
          }}>
          v{process.env.REACT_APP_VERSION} - {PageService.getEnvironment().toUpperCase()}
        </div>
        <AsideMenuList layoutProps={layoutProps} />
      </div>
      {/* end::Menu Container */}
    </>
  );
}
