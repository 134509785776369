import { put, takeLatest, call } from 'redux-saga/effects';
import QueryService from '../../services/QueryService';
import {
  fetchConfigStart,
  fetchConfigSuccess,
  fetchConfigFailure,
} from './configSlice';

function* fetchConfig() {
  try {
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_GetConfig',
      cache: true,
    };

    const result = yield call(QueryService.run, query);

    yield put(fetchConfigSuccess({
      reportConfig: JSON.parse(result[0].Config_Report),
      subscriptionConfig: JSON.parse(result[0].Config_Subscription),
    }));
  } catch (error) {
    yield put(fetchConfigFailure(error.message));
  }
}

export default function* watchConfig() {
  yield takeLatest(fetchConfigStart.type, fetchConfig);
}
