import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  currentItem: {},
  loading: {
    items: false,
    item: false,
    save: false
  },
  error: {
    items: null,
    item: null,
    save: null
  }
};

const isoReportsSlice = createSlice({
  name: 'isoReports',
  initialState,
  reducers: {
    fetchISOReports: (state) => {
      state.loading.items = true;
    },
    fetchISOReportsSuccess: (state, action) => {
      state.items = action.payload;
      state.loading.items = false;
      state.error.items = null;
    },
    fetchISOReportsFailure: (state, action) => {
      state.error.items = action.payload;
      state.loading.items = false;
    },
    fetchISOReportById: (state) => {
      state.loading.item = true;
    },
    fetchISOReportByIdSuccess: (state, action) => {
      const index = state.items.findIndex((item) => item.ReportID === action.payload.ReportID);

      if (index !== -1) {
        // Update existing item
        state.items[index] = action.payload;
      } else {
        // Add new item
        state.items.push(action.payload);
      }

      state.currentItem = action.payload;
      state.loading.item = false;
      state.error.item = null;
    },
    fetchISOReportByIdFailure: (state, action) => {
      state.error.item = action.payload;
      state.loading.item = false;
    },
    saveISOReport: (state) => {
      state.loading.save = true;
    },
    saveISOReportSuccess: (state, action) => {
      const index = state.items.findIndex((item) => item.ReportID === action.payload.ReportID);

      if (index !== -1) {
        // Update existing item
        state.items[index] = action.payload;
      } else {
        // Add new item
        state.items.push(action.payload);
      }

      state.currentItem = action.payload;
      state.loading.save = false;
      state.error.save = false;
    },
    saveISOReportFailure: (state, action) => {
      state.error.save = action.payload;
      state.loading.save = false;
    }
  },
});

export const {
  fetchISOReports,
  fetchISOReportsSuccess,
  fetchISOReportsFailure,
  fetchISOReportById,
  fetchISOReportByIdSuccess,
  fetchISOReportByIdFailure,
  saveISOReport,
  saveISOReportSuccess,
  saveISOReportFailure,
} = isoReportsSlice.actions;

export default isoReportsSlice.reducer;
