import { createSlice } from '@reduxjs/toolkit';
import config from '../config';

const EditReducer = createSlice({
  name: 'edit',
  initialState: config.edit,
  reducers: {
    setEdit: (state, action) => {
      state.name = action.payload.name;
    },
  },
});

export default EditReducer.reducer;
export const { setEdit } = EditReducer.actions;
