import { useState, useEffect } from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [renderModal, setRenderModal] = useState(false);
  const [allowClose, setAllowClose] = useState(true);
  const [willClose, setWillClose] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    setRenderModal(true);
  };

  const closeModal = () => {
    setWillClose(true);
  };

  useEffect(() => {
    if (allowClose && willClose) {
      setIsOpen(false);
      setTimeout(() => {
        setRenderModal(false);
      }, 300);
    }

    setWillClose(false);
  }, [allowClose, willClose]);

  return {
    isOpen, openModal, closeModal, renderModal, allowClose, setAllowClose
  };
};

export default useModal;
