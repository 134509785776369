import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import PageService from '../../services/PageService';

const Configure = () => {
  const history = useHistory();
  const isPathnameCode = PageService.getSecondPath() === '/configure';

  return (
    <div className="topbar-item">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="configure-tooltip">View configuration</Tooltip>}
      >
        <button
          className={`btn btn-icon btn-clean btn-lg mr-1 ${isPathnameCode ? 'btn-primary' : ''}`}
          type="button"
          onClick={() => {
            if (!isPathnameCode) {
              history.push(`${PageService.getBasePath()}/configure`);
            } else {
              history.goBack();
            }
          }}
        >
          <span className={`svg-icon svg-icon-xl ${isPathnameCode ? 'svg-icon-light' : 'svg-icon-primary'}`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Code.svg')} />
          </span>
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default Configure;
