import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ToastModal from './ToastModal';
import { getDataSaveMessage, isDataSaveError, isDataSaving } from '../selectors/DataSelector';

const ToastSaveResult = () => {
  const isSaving = useSelector(isDataSaving);
  const message = useSelector(getDataSaveMessage);
  const isError = useSelector(isDataSaveError);

  const [show, setShow] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current && !isSaving && !isEmpty(message)) {
      setShow(true);
    }
  }, [isSaving, message]);

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  return (
    <ToastModal
      show={show}
      onHide={setShow}
      message={message}
      type={isError ? 'warning' : 'success'}
    />
  );
};

export default ToastSaveResult;
