import React from 'react';
import PreferenceProvider from './providers/PreferenceProvider';

// eslint-disable-next-line react/display-name,func-names
const withPreference = (Component) => function () {
  return (
    <PreferenceProvider>
      <Component />
    </PreferenceProvider>
  );
};

export default withPreference;
