import React from 'react';
import Draggable from 'react-draggable';
import { ModalDialog } from 'react-bootstrap';

const ModalDraggableDialog = ({ ...props }) => (
  <Draggable
    handle=".modal-header"
    grid={[5, 5]}
  >
    <ModalDialog
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  </Draggable>
);

export default ModalDraggableDialog;
