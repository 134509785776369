import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _, { isUndefined, map } from 'lodash';
import QueryService from '../../../services/QueryService';

const createDataMeta = (lookups, dimensions) => {
  // Don't render the columns if the dimensions and lookup are not present.
  if (_.isEmpty(lookups) || _.isEmpty(dimensions)) {
    return [];
  }

  return _.chain(Array(dimensions.length).fill({}))
    // Set name
    .map((dimension, index) => ({
      _orig: dimensions[index],
    }))
    // Fill required values
    .map((dimension) => ({
      ...dimension,
      name: String(dimension._orig.SchemaColumnName),
      label: String(dimension._orig.DisplayName),
      mapTo: String(dimension._orig.MapTo),
      dataTypeId: String(dimension._orig.ControlTypeID),
      defaultValue: dimension._orig.DefaultValue !== null ? dimension._orig.DefaultValue : '',
      isRequired: String(dimension._orig.DefaultValue) === '-1' ? false : String(dimension._orig.IsReq) ?? false,
      displayList: dimension._orig.IsList,
      displayEdit: dimension._orig.IsEdit,
      displayEditWidth: `${dimension._orig.ControlWidthEdit ?? 4}`,
      displayCreate: dimension._orig.IsCreate,
      displayCreateWidth: `${dimension._orig.ControlWidthCreate ?? 4}`,
      displayExport: dimension._orig.IsExport,
      sortOrder: Number(dimension._orig.ColumnOrder),
      groupId: Number(dimension._orig.LayoutGroupID),
      groupName: String(dimension._orig.LayoutGroupName),
      placeholder: '',
    }))
    // Join the dimensions
    .map((dimension) => ({
      ...dimension,
      options: _.filter(lookups, (lookup) => String(dimension._orig.DimensionTypeID) === String(lookup.DimensionTypeID)),
    }))
    // Flatten the dimensions
    .map((dimension) => ({
      ...dimension,
      options: dimension._orig.CustomDataSource !== null
        ? _.map(JSON.parse(dimension._orig.CustomDataSource), (option) => ({
          value: option.value,
          label: option.label,
        }))
        : _.map(dimension.options, (option) => ({
          value: option[dimension.mapTo],
          label: option.DataName,
        })),
    }))
    // Sort the dimensions
    .map((dimension) => ({
      ...dimension,
      options: _.sortBy(dimension.options, (option) => option.label.toLowerCase()),
    }))
    // Add -1 to required options
    .map((dimension) => ({
      ...dimension,
      options: _.toString(dimension.defaultValue) === '-1' ? [{
        value: '-1',
        label: 'All',
      }, ...dimension.options] : dimension.options,
    }))
    // Options KV
    .map((dimension) => ({
      ...dimension,
      optionsKV: Object.assign({}, ..._.map(dimension.options, (option) => ({
        [option.value]: option.label,
      }))),
    }))
    // Get the type of field.
    .map((dimension) => ({
      ...dimension,
      dataType: _.find(lookups, (lookup) => String(lookup.DimensionTypeID) === '15' && String(lookup.dim_key) === dimension.dataTypeId)?.DataCode ?? 'text',
    }))
    .map((lookup) => _.omit(lookup, ['_orig']))
    .value();
};

export const getDataMeta = createAsyncThunk(
  'dataMeta/get',
  async (connections) => {
    const dataMeta = await Promise.all([
      QueryService.run(connections.dimension),
      QueryService.run(connections.lookup),
    ]);
    return createDataMeta(dataMeta[0], dataMeta[1]);
  },
);

export const entityDataMetaAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
  sortComparer: (a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0),
});

const DataMetaReducer = createSlice({
  name: 'dataMeta',
  initialState: {
    ...entityDataMetaAdapter.getInitialState(),
    isLoading: true,
    isInitialised: false,
    columnListSelection: [],
    columnExportSelection: [],
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setColumnListSelection: (state, action) => {
      state.columnListSelection = map(action.payload, (column) => ({
        name: column.name,
        ...!isUndefined(column.sortOrderNew) ? { sortOrder: column.sortOrderNew } : {},
      }));
    },
    setColumnExportSelection: (state, action) => {
      state.columnExportSelection = map(action.payload, (column) => ({ name: column.name, sortOrder: column.sortOrder }));
    },
    setColumnListSelectionNames: (state, action) => {
      state.columnListSelection = action.payload;
    },
    setColumnExportSelectionNames: (state, action) => {
      state.columnExportSelection = action.payload;
    },
    clearColumnListSelection: (state) => {
      state.columnListSelection = [];
    },
    clearColumnExportSelection: (state) => {
      state.columnExportSelection = [];
    },
    loadMetaDataPreferences: (state, action) => {
      state.columnListSelection = action.payload?.columnList ?? [];
      state.columnExportSelection = action.payload?.columnExport ?? [];
      state.isInitialised = true;
    },
  },
  extraReducers: {
    [getDataMeta.pending]: (state) => {
      state.isLoading = true;
    },
    [getDataMeta.fulfilled]: (state, action) => {
      entityDataMetaAdapter.removeAll(state);
      entityDataMetaAdapter.addMany(state, action.payload);
      state.isLoading = false;
    },
    [getDataMeta.rejected]: (state, { error }) => {
      state.isLoading = false;
      console.error(error);
    },
  },
});

export default DataMetaReducer.reducer;
export const {
  setDataMeta, setIsLoading, setColumnListSelection, setColumnExportSelection,
  clearColumnListSelection, clearColumnExportSelection, setColumnListSelectionNames, setColumnExportSelectionNames, loadMetaDataPreferences,
} = DataMetaReducer.actions;
