import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { hasDataChanges, isDataSaving } from '../selectors/DataSelector';
import { clearDataModified, saveDataModified } from '../reducers/DataModifiedReducer';

const ListSave = () => {
  const dispatch = useDispatch();

  const hasChanges = useSelector(hasDataChanges);
  const isSaving = useSelector(isDataSaving);

  return hasChanges && (
    <div className="footer bg-white py-4 d-flex flex-lg-column">
      <div className="container-fluid d-flex flex-row-reverse">
        <div style={{ margin: 'auto' }}>
          <Button className="mr-2" variant="secondary" onClick={() => dispatch(clearDataModified())}>Cancel</Button>
          <Button variant="primary" onClick={() => dispatch(saveDataModified())} disabled={isSaving}>
            Save Changes
            {isSaving && <span className="ml-2 pr-5  spinner spinner-white" />}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ListSave;
