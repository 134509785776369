import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Card, CardBody } from '../../../_metronic/_partials/controls';
import { getDataModifiedFiltered } from '../selectors/DataSelector';
import { getExportByName } from '../selectors/ExportSelector';
import { getFiltersTop } from '../selectors/FilterSelector';
import ListFilter from './ListFilter';
import ListTable from './ListTable';
import ListActions from './ListActions';
import ListSave from './ListSave';
import { getColumnsExport } from '../selectors/DataMetaSelector';
import ButtonClearFilters from './ButtonClearFilters';
import PageService from '../../../services/PageService';
import useSelectorCondition from '../hooks/useSelectorCondition';

const ListPage = () => {
  const data = useSelectorCondition(getDataModifiedFiltered, [], !PageService.isModalOpen());
  const exportConfig = useSelector((state) => getExportByName(state, 'csv'));
  const exportColumns = useSelector(getColumnsExport);
  const filters = useSelector(getFiltersTop);

  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={exportColumns}
          exportCSV={exportConfig}
        >
          {(props) => (
            <>
              <Row>
                <Col xs={10}>
                  <Row>
                    {_.map(filters, (filter) => (
                      <Col xs={2} key={filter.name}>
                        <ListFilter filter={filter} key={filter} />
                      </Col>
                    ))}
                    <Col xs={2}>
                      <ButtonClearFilters />
                    </Col>
                  </Row>
                </Col>
                <Col xs={2}>
                  <ListActions onExport={props.csvProps.onExport} />
                </Col>
              </Row>
              <ListTable baseProps={props.baseProps} />
            </>
          )}
        </ToolkitProvider>
        <ListSave />
      </CardBody>
    </Card>
  );
};

export default ListPage;
