import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalBodyLoading = () => (
  <Modal.Body className="overlay overlay-block cursor-default my-4">
    <div className="overlay-layer bg-transparent">
      <div className="spinner spinner-lg spinner-success" />
    </div>
  </Modal.Body>
);

export default ModalBodyLoading;
