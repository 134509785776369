import React, { Suspense, useState } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import axios from 'axios';
import { KTCookie } from '../../_metronic/_assets/js/components/cookie';
import { setFields } from './reducers/SaveReducer';
import { LayoutSplashScreen } from '../../_metronic/layout';
import DisablePage from './disable/DisablePage';
import ConfigurePage from './configure/ConfigurePage';
import config from './config';
import CreatePage from './change/CreatePage';
import EditPage from './change/EditPage';
import EnablePage from './enable/EnablePage';
import ListPage from './list/ListPage';
import store from './store';
import withData from './withData';
import withPreference from './withPreference';
import CopyPage from './change/CopyPage';

async function getFields() {
  const response = await axios.get(`${window.env.API_URL}/config`, { headers: { Authorization: `Bearer ${KTCookie.getCookie('jwt')}` } });
  return JSON.parse(response.data.config.subscriptionConfig);
}

const SubscriptionsPage = () => (
  <Provider store={store}>
    <SubscriptionsPageWithStore />
  </Provider>
);

const SubscriptionsPageInternal = ({ location }) => (
  <Suspense fallback={<LayoutSplashScreen />}>
    <Switch>
      <Redirect
        exact
        from="/subscriptions"
        to="/subscriptions/list"
      />
      <Route path="/subscriptions/configure">
        <ConfigurePage
          code={config}
        />
      </Route>
      <Route path="/subscriptions/:id/edit">
        {({ history: historyLocal, match }) => (
          <EditPage
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              historyLocal.push('/subscriptions/list');
            }}
          />
        )}
      </Route>
      <Route path="/subscriptions/:id/copy">
        {({ history: historyLocal, match }) => (
          <CopyPage
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              historyLocal.push('/subscriptions/list');
            }}
          />
        )}
      </Route>
      <Route path="/subscriptions/create">
        {({ history: historyLocal, match }) => (
          <CreatePage
            show={match != null}
            onHide={() => {
              historyLocal.push('/subscriptions/list');
            }}
          />
        )}
      </Route>
      <Route path="/subscriptions/:id/disable">
        {({ match }) => (
          <DisablePage
            id={match && match.params.id}
          />
        )}
      </Route>
      <Route path="/subscriptions/:id/enable">
        {({ match }) => (
          <EnablePage
            id={match && match.params.id}
          />
        )}
      </Route>
    </Switch>
    {location.pathname.indexOf('/configure') === -1 && <ListPage />}
  </Suspense>
);

const SubscriptionsPageWithStore = () => {
  const dispatch = useDispatch();
  const [hasLoadedFields, setHasLoadedFields] = useState(false);

  getFields().then((fields) => {
    dispatch(setFields(fields));
    setHasLoadedFields(true);
  });

  return hasLoadedFields ? withData(withPreference(withRouter(SubscriptionsPageInternal)))() : 'Loading...';
};

export default SubscriptionsPage;
