import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isFiltersChanged } from '../selectors/FilterSelector';
import { clearFilterState } from '../reducers/FilterReducer';

const ButtonClearFilters = () => {
  const dispatch = useDispatch();
  const isChanged = useSelector(isFiltersChanged);

  return (
    <Button
      type="button"
      variant="success"
      className="btn btn-primary mr-2"
      style={{ marginTop: '1px' }}
      disabled={!isChanged}
      onClick={() => {
        dispatch(clearFilterState());
      }}
    >
      Clear All
    </Button>
  );
};

export default ButtonClearFilters;
