import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import { Container } from '@material-ui/core';

const JumbotronWarning = ({
  iconClassName,
  title,
  children,
}) => (
  <Jumbotron className="mt-2">
    <Container className="text-center">
      <i className={`${iconClassName} display-2 mb-3 text-primary`} />
      <p className="display-4">{title}</p>
      <p className="lead">{children}</p>
    </Container>
  </Jumbotron>
);

export default JumbotronWarning;
