import { combineReducers } from 'redux';
import ExportReducer from './ExportReducer';
import CreateReducer from './CreateReducer';
import EditReducer from './EditReducer';
import ListReducer from './ListReducer';
import SaveReducer from './SaveReducer';
import DataReducer from './DataReducer';
import PreferenceReducer from './PreferenceReducer';
import DataMetaReducer from './DataMetaReducer';
import DataModifiedReducer from './DataModifiedReducer';
import DataFilterReducer from './DataFilterReducer';
import FilterReducer from './FilterReducer';
import CopyReducer from './CopyReducer';

export default combineReducers({
  // Based on config
  export: ExportReducer,
  edit: EditReducer,
  create: CreateReducer,
  copy: CopyReducer,
  filter: FilterReducer,
  save: SaveReducer,
  list: ListReducer,

  // Dynamic info
  preference: PreferenceReducer,
  data: DataReducer,
  dataMeta: DataMetaReducer,
  dataModified: DataModifiedReducer,
  dataFilter: DataFilterReducer,
});
