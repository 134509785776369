import React, { useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map } from 'lodash';
import IconButton from '../../../components/IconButton';
import ListActionsListColumns from './ListActionsListColumns';
import ListActionExportColumns from './ListActionsExportColumns';
import {
  clearColumnExportSelection,
  clearColumnListSelection,
  setColumnExportSelection,
  setColumnListSelection,
} from '../reducers/DataMetaReducer';
import { getDataMetaCount } from '../selectors/DataMetaSelector';
import { isListCompact } from '../selectors/ListSelector';
import { toggleListCompact } from '../reducers/ListReducer';
import { getDataCount } from '../selectors/DataSelector';
import {
  getFilterNotOptionLabelString, getFilterNotOptionValueString,
  getFiltersDropdown,
} from '../selectors/FilterSelector';
import { setFilterState } from '../reducers/FilterReducer';

const ListActions = ({ onExport, history }) => {
  const hasChanged = useRef(false);

  const dispatch = useDispatch();
  const disableButtonAdd = useSelector((state) => getDataMetaCount(state) === 0 || getDataCount(state) === 0);
  const isCompact = useSelector(isListCompact);
  const filters = useSelector(getFiltersDropdown);

  const [showModalListColumns, setShowModalListColumns] = useState(false);
  const [showModalExportColumns, setShowModalExportColumns] = useState(false);

  return (
    <div className="text-right">
      <Button
        type="button"
        className="btn btn-primary mr-2"
        disabled={disableButtonAdd}
        onClick={() => {
          history.push('/subscriptions/create');
        }}
      >
        Add Subscription
      </Button>
      <IconButton
        btnColor="primary"
        btnClass="mr-2"
        iconColor="light"
        iconClass="fas fa-cloud-download-alt"
        tooltip="Download as CSV"
        onClick={() => onExport()}
      />
      <Dropdown className="d-inline">
        <Dropdown.Toggle
          as={IconButton}
          btnColor="secondary"
          iconClass="fas fa-cog"
          tooltip="Page settings"
        />
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModalListColumns(true)}>Edit table columns</Dropdown.Item>
          <Dropdown.Item onClick={() => setShowModalExportColumns(true)}>Edit CSV columns</Dropdown.Item>
          <Dropdown.Item onClick={() => {
            dispatch(toggleListCompact());
          }}
          >
            {isCompact ? 'Expanded' : 'Compact'}
            {' '}
            View
          </Dropdown.Item>
          {map(filters, (filter) => (
            <Dropdown.Item
              key={filter.name}
              onClick={() => {
                dispatch(setFilterState({ name: filter.name, value: getFilterNotOptionValueString(filter) }));
              }}
            >
              {getFilterNotOptionLabelString(filter)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <ListActionsListColumns
        show={showModalListColumns}
        onHide={() => setShowModalListColumns(false)}
        onSave={(columns) => {
          setShowModalListColumns(false);
          if (hasChanged.current) {
            dispatch(setColumnListSelection(columns));
          }
        }}
        onChange={() => {
          hasChanged.current = true;
        }}
        onReset={() => {
          hasChanged.current = false;
          dispatch(clearColumnListSelection());
        }}
      />
      <ListActionExportColumns
        show={showModalExportColumns}
        onHide={() => setShowModalExportColumns(false)}
        onSave={(columns) => {
          setShowModalExportColumns(false);
          if (hasChanged.current) {
            dispatch(setColumnExportSelection(columns));
          }
        }}
        onChange={() => {
          hasChanged.current = true;
        }}
        onReset={() => {
          hasChanged.current = false;
          dispatch(clearColumnExportSelection());
        }}
      />
    </div>
  );
};

export default withRouter(ListActions);
