import React from 'react';
import JSONInput from 'react-json-editor-ajrm';
import { Card, CardBody } from '../../../_metronic/_partials/controls';

const ConfigurePage = ({ code }) => (
  <Card style={{ height: '72rem' }}>
    <CardBody>
      <JSONInput
        placeholder={code}
        colors={{
          string: '#DAA520', // overrides theme colors with whatever color value you want
        }}
        height="100%"
        width="100%"
      />
    </CardBody>
  </Card>
);

export default ConfigurePage;
