import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Alert, Box, CircularProgress, Tabs, Tab } from '@mui/material';
import { fetchISOReportById, saveISOReport } from 'src/redux';
import ModalDraggableDialog from '../../components/modals/ModalDraggableDialog';
import ReportForm from './ReportForm';
import DDRSForm from './DDRSForm';
import CustomStepper from './CustomStepper';
import { parseDDRSValues, generateDDRSValidationSchema } from './util';

const ReportModal = ({ isOpen, closeModal, exitedModal, columns, initialValues, allowClose, setAllowClose }) => {
  const dispatch = useDispatch();
  const modalRoot = useRef(document.createElement('div'));

  const ssrsReports = useSelector((state) => state.ssrsReports.ssrsReports);
  const currentISOReport = useSelector((state) => state.isoReports.currentItem);

  const step1SubmitLoading = useSelector((state) => state.isoReports.loading.save);
  const step1SubmitError = useSelector((state) => state.isoReports.error.save);
  const step2SubmitLoading = useSelector((state) => state.isoReports.loading.item);
  const step2SubmitError = useSelector((state) => state.isoReports.error.item);
  const step3SubmitLoading = useSelector((state) => state.isoReports.loading.save);
  const step3SubmitError = useSelector((state) => state.isoReports.error.save);

  const [step1Submitted, setStep1Submitted] = useState(false);
  const [step1Finished, setStep1Finished] = useState(false);
  const [step2Submitted, setStep2Submitted] = useState(false);
  const [step2Finished, setStep2Finished] = useState(false);
  const [step3Submitted, setStep3Submitted] = useState(false);
  const [step3Finished, setStep3Finished] = useState(false);

  const [editSubmitted, setEditSubmitted] = useState(false);
  const [editFinished, setEditFinished] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const steps = ['Step 1', 'Step 2', 'Step 3'];

  const [selectedReport, setSelectedReport] = useState(null);

  const step1FormikRef = useRef(null);
  const step3FormikRef = useRef(null);

  // The Report Selection control which replaces the ReportID and ReportName fields (only used for creating a new report)
  const createReportSelectionControl = () => ({
    LayoutGroupID: 1,
    ControlTypeID: 0,
    DimensionTypeID: null,
    DisplayName: 'Select SSRS Report',
    SchemaColumnName: 'ReportSelection',
    IsEdit: true,
    items: ssrsReports.map((report) => ({ name: report.ReportName, id: report.ReportID })),
  });

  const excludedSchemaColumnNames = ['ReportID', 'ReportName', 'ReportServerName'];

  const step1Columns = initialValues
    ? columns.filter((column) => column.LayoutGroupID === 1)
    : [createReportSelectionControl(),
      ...columns.filter((column) => (
        column.LayoutGroupID === 1 && !excludedSchemaColumnNames.includes(column.SchemaColumnName)
      ))
    ];

  const step3Columns = columns.filter((column) => column.LayoutGroupID === 3);

  const step3groups = step3Columns.reduce((acc, column) => {
    const groupID = column.DDRSGroupID;
    if (!acc[groupID]) {
      // eslint-disable-next-line no-param-reassign
      acc[groupID] = [];
    }

    acc[groupID].push(column);
    return acc;
  }, {});

  const step3SortedGroups = Object.entries(step3groups)
    .sort(([groupIDA], [groupIDB]) => groupIDA - groupIDB)
    .reduce((acc, [groupID, cols]) => {
      // eslint-disable-next-line no-param-reassign
      acc[groupID] = cols;
      return acc;
    }, {});

  const step1ValidationSchema = Yup.object().shape({
    ...(initialValues ? {} : { ReportSelection: Yup.string().required('Report selection is required') }), // Only include ReportSelection validation when creating a new report
    ValidDateRangeTypes: Yup.string().required('At least one valid date range is required'),
    Active: Yup.boolean().required('Active state is required'),
    LookupTable: Yup.string().required('Lookup table is required')
  });

  const step3ValidationSchema = generateDDRSValidationSchema(step3Columns);

  const getValuesByLayoutGroup = useCallback((values, layoutGroupId) => {
    if (!values) return undefined;
    return Object.fromEntries(
      Object.entries(values)
        .filter(([key]) => (
          columns.some((column) => column.SchemaColumnName === key && column.LayoutGroupID === layoutGroupId)))
    );
  }, [columns]);

  const [step1InitialValues, setStep1InitialValues] = useState();
  const [step3InitialValues, setStep3InitialValues] = useState();

  useEffect(() => {
    console.log(initialValues);
    const step1Values = getValuesByLayoutGroup(initialValues, 1);
    const step3Values = getValuesByLayoutGroup(initialValues, 3);

    setStep1InitialValues(step1Values);
    setStep3InitialValues(step3Values);
  }, [initialValues, getValuesByLayoutGroup]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleTabChange = (e, newTab) => {
    setActiveTab(newTab);
  };

  const handleStep1Submit = (values) => {
    const selectedSSRSReport = ssrsReports.find((report) => report.ReportID === values.ReportSelection);
    setSelectedReport(selectedSSRSReport);

    const dispatchValues = {
      ...values,
      ReportID: selectedSSRSReport.ReportID,
      ReportName: selectedSSRSReport.ReportName,
    };

    setStep1Finished(false);
    dispatch(saveISOReport(dispatchValues));
    setStep1Submitted(true);
    setAllowClose(false);
  };

  const handleStep2Submit = () => {
    if (!currentISOReport) return;

    setStep2Finished(false);
    dispatch(fetchISOReportById(currentISOReport.ReportID));
    setStep2Submitted(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleStep3Submit = (values) => {
    console.log('handleStep3Submit', values);
    console.log('currentISOReport', currentISOReport);

    // If no values to submit, create report completed
    if (!values || Object.keys(values).length === 0) {
      setStep3Finished(true);
      setStep3Submitted(true);
      return;
    }

    console.log('values', values);

    // Combine values
    const dispatchValues = {
      ReportDBID: currentISOReport.ReportDBID,
      ...getValuesByLayoutGroup(currentISOReport, 1),
      ...values
    };

    console.log('dispatchValues', dispatchValues);

    setStep3Finished(false);
    dispatch(saveISOReport(dispatchValues));
    setStep3Submitted(true);
  };

  const submitForm = async () => {
    if (initialValues) {
      const step1Touched = Object.keys(getValuesByLayoutGroup(initialValues, 1)).reduce((acc, key) => (
        { ...acc, [key]: true }
      ), {});
      step1FormikRef.current.setTouched(step1Touched);

      const step3Touched = Object.keys(getValuesByLayoutGroup(initialValues, 3)).reduce((acc, key) => (
        { ...acc, [key]: true }
      ), {});
      step3FormikRef.current.setTouched(step3Touched);

      const step1Errors = await step1FormikRef.current.validateForm();
      const step3Errors = await step3FormikRef.current.validateForm();

      // Check if any errors exist in the forms
      const step1HasErrors = Object.keys(step1Errors).length !== 0;
      const step3HasErrors = Object.keys(step3Errors).length !== 0;

      if (step1HasErrors || step3HasErrors) {
        console.log(step1Errors, step3Errors);
        // If any form has errors, set the active tab to the form containing errors
        setActiveTab(step1HasErrors ? 0 : 1);
        return;
      }

      const values = {
        ReportDBID: initialValues.ReportDBID,
        ...step1FormikRef.current.values,
        ...parseDDRSValues(step3FormikRef.current.values, step3groups)
      };

      setEditFinished(false);
      dispatch(saveISOReport(values));
      setEditSubmitted(true);
    } else {
      // if (activeStep === 0 && !step1FormikRef.current.isValid) return;

      switch (activeStep) {
        case 0:
          if (step1Submitted) setActiveStep(1);
          else step1FormikRef.current.submitForm();
          break;
        case 1:
          handleStep2Submit();
          break;
        case 2:
          step3FormikRef.current.submitForm();
          break;
        default:
          console.error('Unknown step');
      }
    }
  };

  const stepsContent = [
    <ReportForm
      formikRef={step1FormikRef}
      onSubmit={handleStep1Submit}
      columns={step1Columns}
      initialValues={step1InitialValues}
      validationSchema={step1ValidationSchema}
      readOnly={step1Submitted}
      key="step1"
    />,
    <div key="step2">
      {
        selectedReport
        && (
          <div>
            <div className="text-center mb-5">
              <p className="h4 mb-3">Set up a data driven report subscription</p>
              <Button color="primary" href={selectedReport.SubEditURL} target="_blank">
                Go to SSRS
              </Button>
            </div>

            <p className="mb-0">
              <br />
              {`Report Name: ${selectedReport.ReportName}`}
              <br />
              {`Report ID: ${selectedReport.ReportID}`}
            </p>
          </div>
        )
      }
    </div>,
    <DDRSForm
      formikRef={step3FormikRef}
      onSubmit={handleStep3Submit}
      columns={step3Columns}
      groups={step3SortedGroups}
      initialValues={step3InitialValues}
      validationSchema={step3ValidationSchema}
      key="step3"
    />
  ];

  const renderCircularProgress = () => {
    if (step1SubmitLoading || step2SubmitLoading || step3SubmitLoading) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    return null;
  };

  // Insert modal into DOM
  useEffect(() => {
    const root = modalRoot.current;
    document.body.appendChild(root);

    return () => {
      document.body.removeChild(root);
    };
  }, []);

  // useEffect for tracking Step 1 submit and loading progress
  useEffect(() => {
    if (step1Submitted && !step1SubmitLoading && !step1Finished) {
      if (!step1SubmitError) {
        // Submit success, move to step 2
        setActiveStep(1);
      } else {
        // Submit failure, allow dialog close (and stay on step 1)
        setAllowClose(true);
      }

      setStep1Finished(true);
    } else if (step1SubmitLoading) {
      setStep1Finished(false);
    }
  }, [step1Submitted, step1SubmitLoading, step1Finished, step1SubmitError, setAllowClose]);

  // useEffect for tracking Step 2 submit and loading progress
  useEffect(() => {
    if (step2Submitted && !step2SubmitLoading && !step2Finished) {
      if (!step2SubmitError) {
        // Update formik values
        setStep3InitialValues(getValuesByLayoutGroup(currentISOReport, 3));
        // Move to step 3
        setActiveStep(2);
      }

      setStep2Finished(true);
    } else if (step2SubmitLoading) {
      setStep2Finished(false);
    }
  }, [step2Submitted, step2SubmitLoading, step2Finished, step2SubmitError, currentISOReport, getValuesByLayoutGroup]);

  // useEffect for tracking Step 3 submit and loading progress
  useEffect(() => {
    if (step3Submitted && !step3SubmitLoading && !step3Finished) {
      if (!step3SubmitError) {
        setAllowClose(true);
        closeModal();
      }

      setStep3Finished(true);
    } else if (step3SubmitLoading) {
      setStep3Finished(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step3Submitted, step3SubmitLoading, step3Finished, step3SubmitError]);

  // useEffect for tracking Edit submit and loading progress
  useEffect(() => {
    if (editSubmitted && !step1SubmitLoading && !editFinished) {
      if (!step1SubmitError) {
        setAllowClose(true);
        closeModal();
      }

      setEditFinished(true);
    }
  }, [editSubmitted, step1SubmitLoading, editFinished, step1SubmitError, setAllowClose, closeModal]);

  // useEffects for updating formik values
  useEffect(() => {
    if (step1FormikRef.current) {
      step1FormikRef.current.resetForm({ values: step1InitialValues });
    }
  }, [step1InitialValues]);

  useEffect(() => {
    if (step3FormikRef.current) {
      step3FormikRef.current.resetForm({ values: step3InitialValues });
    }
  }, [step3InitialValues]);

  return ReactDOM.createPortal(
    <Modal show={isOpen} onHide={closeModal} size="lg" dialogAs={ModalDraggableDialog} onExited={exitedModal}>
      <Modal.Header closeButton={allowClose}>
        <Modal.Title>
          {`${initialValues ? 'Edit' : 'Create'} Report`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: initialValues ? 0 : undefined }}>
        {renderCircularProgress()}
        {
          initialValues ? (
            <Box>
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Report Details" />
                <Tab label="DDRS Mapping" />
              </Tabs>

              <Box sx={{ mt: 3, mb: 1 }}>
                <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>{stepsContent[0]}</div>
                <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>{stepsContent[2]}</div>
              </Box>
            </Box>
          ) : (
            <CustomStepper
              activeStep={activeStep}
              onStepChange={handleStepChange}
              steps={steps}
              stepsContent={stepsContent}
            />
          )
        }

        {
          (activeStep === 0 && !initialValues)
          && (
            <p className="mb-0 mt-4">
              <small>Note: Once you hit next, you must complete the next steps or the report will not be set up properly.</small>
            </p>
          )
        }

        {
          (step1Finished && step1SubmitError)
          && (
            <Alert className="mt-4" severity="error">{ step1SubmitError }</Alert>
          )
        }
      </Modal.Body>

      <Modal.Footer>
        {
          (activeStep === 0 && initialValues)
          && (
            <Button variant="warning" href={initialValues.SubEditURL} target="_blank">
              SSRS Report
            </Button>
          )
        }

        <div className="flex-grow-1" />

        {
          (activeStep === 0 && !step1Submitted)
          && (
            <Button variant="secondary" onClick={closeModal} disabled={!allowClose}>
              Cancel
            </Button>
          )
        }

        <Button variant="primary" onClick={submitForm}>
          {initialValues ? 'Save Changes' : (activeStep === steps.length - 1 ? 'Finish' : 'Next')}
        </Button>
      </Modal.Footer>
    </Modal>,
    modalRoot.current
  );
};

export default ReportModal;
