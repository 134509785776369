import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import ChangeForm from './ChangeForm';
import ModalDraggableDialog from '../../../components/modals/ModalDraggableDialog';
import { getEditTitle } from '../selectors/EditSelector';
import { getDataMetaEdit } from '../selectors/DataMetaSelector';
import { getDataById } from '../selectors/DataSelector';

const EditPage = ({ id, show, onHide }) => {
  const allowModalClickOffHide = useRef(true);

  const fields = useSelector((state) => getDataById(state, id));
  const fieldGroups = groupBy(useSelector(getDataMetaEdit), 'groupId');
  const title = useSelector((state) => getEditTitle(state, id));

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        if (allowModalClickOffHide.current) {
          onHide();
        }
      }}
      dialogAs={ModalDraggableDialog}
    >
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ChangeForm
        id={id}
        onHide={onHide}
        fields={fields}
        fieldGroups={fieldGroups}
        onChange={() => {
          allowModalClickOffHide.current = false;
        }}
        allowCopy
        allowDelete
      />
    </Modal>
  );
};

export default EditPage;
