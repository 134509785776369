import { put, takeLatest, call } from 'redux-saga/effects';
import QueryService from '../../services/QueryService';
import {
  fetchISOReportSchemeStart,
  fetchISOReportSchemeSuccess,
  fetchISOReportSchemeFailure,
} from './isoReportSchemeSlice';

function* fetchISOReportScheme() {
  try {
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_GetISOReports @Data_Scheme = 1',
      cache: true,
    };

    const result = yield call(QueryService.run, query);

    yield put(fetchISOReportSchemeSuccess(result));
  } catch (error) {
    yield put(fetchISOReportSchemeFailure(error.message));
  }
}

export default function* watchISOReportScheme() {
  yield takeLatest(fetchISOReportSchemeStart.type, fetchISOReportScheme);
}
