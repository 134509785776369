import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { clone, isEmpty } from 'lodash';
import { getData, getDataById } from '../selectors/DataSelector';
import { addDataModifiedHidden, saveDataModified } from '../reducers/DataModifiedReducer';

const EnablePage = ({ id }) => {
  const dispatch = useDispatch();
  const data = useSelector(getData);
  const row = clone(useSelector((state) => getDataById(state, id)));

  if (isEmpty(data)) {
    return null;
  }

  row.Active = 1;
  dispatch(addDataModifiedHidden(row));
  dispatch(saveDataModified());

  return (
    <Redirect to="/subscriptions/list" />
  );
};

export default EnablePage;
