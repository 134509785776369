/* eslint no-use-before-define: 0 */
import _, {
  chain, has, map, values,
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { entityDataAdapter } from '../reducers/DataReducer';
import { getFiltersKeyValue, replaceFilterMergeFields } from './FilterSelector';
import { entityDataModifiedAdapter } from '../reducers/DataModifiedReducer';
import { getListIndex, replacePageIdMergeFields } from './ListSelector';
import { replaceMergeFields } from '../util';
import config from '../config';

export const {
  selectById: getDataById,
  selectIds: getDataIds,
  selectEntities: getDataKeyValue,
  selectAll: getData,
  selectTotal: getDataCount,
} = entityDataAdapter.getSelectors((state) => state.data);

export const {
  selectById: getDataModifiedByName,
  selectIds: getDataModifiedIds,
  selectEntities: getDataModifiedKeyValue,
  selectTotal: getDataModifiedCount,
} = entityDataModifiedAdapter.getSelectors((state) => state.dataModified);

/**
 * Returns a list of only modified data.
 */
export const getDataModified = (state) => values(getDataModifiedKeyValue(state));

/**
 * Returns true / false if the data has been changed.
 */
export const hasDataChanges = (state) => !state.dataModified.entitiesHidden && getDataModifiedCount(state) > 0;

/**
 * Returns information on the saved rows, and the time this happened.
 */
export const getSavedTimestamp = (state) => state.dataModified.savedTimeStamp;
export const getSavedRows = (state) => ((Date.now() - getSavedTimestamp(state)) / 1000 < 5 ? state.dataModified.savedRows : []);
export const getSavedRowIds = (state) => map(getSavedRows(state), config.list.index);

/**
 * Returns a row of data, without the id field in it.
 * This is useful when wanting to clone a row.
 */
export const getDataByIdWithoutId = (state, id) => _.omit(getDataById(state, id), [config.list.index, 'id']);

/**
 * Get a list of saved rows that happened in the last few seconds.
 * This allows particular events to happen within a selected window.
 */
export const getSavedRowsRecent = (state, duration) => ((Date.now() - getSavedTimestamp(state)) / 1000 < duration ? getSavedRows(state) : []);
export const getSavedRowIdsRecent = (state, duration) => map(getSavedRowsRecent(state, duration), config.list.index);

/**
 * Get information about the save state.
 */
export const isDataSaving = (state) => state.dataModified.isSaving;
export const isDataSaveError = (state) => state.dataModified.saveError;
export const getDataSaveMessage = (state) => state.dataModified.saveMessage;
export const isDataLoading = (state) => state.data.isLoading;

/**
 * Replace a connection with statement with all the filter, and row values
 */
export const replaceConnection = (state, connection, rowId) => ({
  ...connection,
  statement: replaceFilterMergeFields(state, replaceDataMergeFields(state, rowId, replacePageIdMergeFields(connection.statement))),
});

/**
 * Get the data filtered by the current the current values.
 */
export const getDataFiltered = createSelector(
  [
    getData,
    (state) => getFiltersKeyValue(state),
  ],
  (data, filters) => {
    const filterValues = _.chain(filters)
      .pickBy({ isRemoteFilter: false })
      .mapValues((filter) => filter.state || filter.initialState)
      .mapValues((value) => (!_.isNaN(_.toNumber(value)) ? _.toNumber(value) : value))
      .pickBy((value) => value !== -1)
      .value();
    return _.filter(data, filterValues);
  },
);

/**
 * Get the modified data filtered, by the current the current values.
 */
export const getDataModifiedFiltered = createSelector(
  [
    (state) => getDataFiltered(state),
    (state) => getDataModifiedKeyValue(state),
    (state) => getListIndex(state),
  ],
  (data, dataModified, index) => chain(data)
    .map((row) => (has(dataModified, String(row[index])) ? {
      ...dataModified[String(row[index])],
      _original: row,
      _modified: true,
    } : {
      ...row,
      _original: row,
      _modified: false,
    }))
    .value(),
);

/**
 * Replaces all merge fields in a string, given the current row as an input.
 */
export const replaceDataMergeFields = (state, rowId, string) => replaceMergeFields(getDataById(state, rowId), string);
