import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {
  find, includes, isEqual, omit,
} from 'lodash';
import JumbotronWarning from '../../../components/JumbotronWarning';
import { getSavedRowIdsRecent, isDataLoading } from '../selectors/DataSelector';
import { getColumnsList, isDataMetaLoading } from '../selectors/DataMetaSelector';
import { getListEdit, getListIndex, getListPaginationWithData } from '../selectors/ListSelector';
import { addDataModified, removeDataModified } from '../reducers/DataModifiedReducer';
import PaginationProvider from '../providers/PaginationProvider';
import { setPaginationSizePerPage } from '../reducers/ListReducer';
import PageService from '../../../services/PageService';
import useSelectorConditionMemo from '../hooks/useSelectorConditionMemo';

const filter = filterFactory();

const ListTable = ({ baseProps }) => {
  const dispatch = useDispatch();

  const dataIndex = useSelector(getListIndex);
  const columns = useSelectorConditionMemo(getColumnsList, [], !PageService.isModalOpen());
  const savedRowIds = useSelector((state) => getSavedRowIdsRecent(state, 3000));
  const pagination = useSelector((state) => getListPaginationWithData(state, baseProps.data));
  const edit = useSelector(getListEdit);
  const isLoading = useSelector((state) => isDataLoading(state) || isDataMetaLoading(state));

  const onCellEdit = (type, { cellEdit: { rowId, dataField, newValue } }) => {
    const row = find(baseProps.data, (dataRow) => dataRow[dataIndex].toString() === rowId.toString()) || {};

    const rowOld = row._original ?? {};
    const rowNew = omit({ ...row, [dataField]: newValue }, ['_modified', '_original']);

    if (!isEqual(rowOld, rowNew)) {
      dispatch(addDataModified(rowNew));
    } else {
      dispatch(removeDataModified(rowNew.id));
    }
  };

  const onPageChange = (size) => {
    dispatch(setPaginationSizePerPage(size));
  };

  if ((baseProps.data.length === 0 || columns.length === 0) && isLoading) {
    return null;
  }
  if (baseProps.data.length === 0 || columns.length === 0) {
    return (
      <JumbotronWarning
        iconClassName="fad fa-search"
        title="There are no results"
      >
        It looks like we haven&apos;t been able to get any data
        <br />
        Try changing one of the filters and see if we can find something for you.
      </JumbotronWarning>
    );
  }

  return (
    <PaginationProvider pagination={pagination} baseProps={baseProps} onPageChange={onPageChange}>
      {({ paginationProps }) => (
        <BootstrapTable
            /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...paginationProps}
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center table-sticky-1"
          bootstrap4
          bordered={false}
          headerWrapperClasses="thead-dark text-white"
          filter={filter}
          filterPosition="top"
          columns={columns}
          rowClasses={(row) => {
            if (includes(savedRowIds, row.id)) {
              return 'rowpulse';
            }
            return '';
          }}
          remote={{ cellEdit: true }}
          cellEdit={cellEditFactory(edit)}
          onTableChange={onCellEdit}
        />
      )}
    </PaginationProvider>
  );
};
export default ListTable;
