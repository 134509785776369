import React from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ChangedColumnFormatter from './ChangedColumnFormatter';

const DimensionColumnFormatter = (cell, row, rowIndex, column) => {
  const values = _.split(cell, ',');
  const valuesValid = !_.includes(_.map(values, (value) => _.has(column.optionsKV, value)), false);
  const labels = _.join(_.map(values, (value) => (_.has(column.optionsKV, value) ? column.optionsKV[value] : value)), ', ');

  if (!valuesValid) {
    return (
      <OverlayTrigger overlay={<Tooltip id="cell-alert">The lookup for this value could not be found.</Tooltip>}>
        <div className="td-wrapper alert-danger">
          {labels}
        </div>
      </OverlayTrigger>
    );
  }
  return (
    <ChangedColumnFormatter row={row}>
      {labels}
    </ChangedColumnFormatter>
  );
};

export default DimensionColumnFormatter;
