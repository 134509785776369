const config = {
  export: [
    {
      name: 'csv',
      label: 'CSV',
      fileName: 'Subscriptions {date}.csv',
    },
  ],
  edit: {
    title: 'Edit Subscription - {subscriptionId} - {reportName}',
  },
  create: {
    title: 'Create Subscription - {reportName}',
  },
  copy: {
    title: 'Copy Subscription - {subscriptionId} - {reportName}',
  },
  filter: [
    {
      connection: {
        datasource: 'TRG-55UA-DW1',
        statement: "EXEC ISODataWarehouse.ISO.RPS_GetEmailAddresses @Disabled = '{disabled}'",
        value: 'Email',
        label: 'Email',
        cache: true,
      },
      name: 'Email',
      type: 'select',
      label: 'Email',
      initialState: null,
      isRemoteFilter: true,
      isClearable: true,
      helpText: 'Search by Email',
      showLoading: true,
      clearGroupId: 1,
      preloadMetaData: true,
      position: 'top',
    },
    {
      name: 'SubscriptionId',
      type: 'text',
      label: 'Subscription Id',
      initialState: null,
      isRemoteFilter: true,
      isClearable: true,
      helpText: 'Search by Subscription Id',
      clearGroupId: 1,
      position: 'top',
    },
    {
      connection: {
        datasource: 'TRG-55UA-DW1',
        statement: "EXEC ISODataWarehouse.ISO.RPS_GetReports @Disabled = '{disabled}'",
        value: 'ReportID',
        label: 'ReportName',
        cache: true,
      },
      name: 'ReportID',
      type: 'select',
      label: 'Report',
      labelPlural: 'Reports',
      labelIncludeCount: false,
      initialState: null,
      isRemoteFilter: true,
      helpText: 'Search by Report',
      clearGroupId: 1,
      position: 'top',
    },
    {
      connection: {
        datasource: 'TRG-55UA-DW1',
        statement: 'EXEC ISODataWarehouse.ISO.RPT_DDRS_DDGetScheduleTypes',
        value: 'ScheduleTypeID',
        label: 'ScheduleType',
        cache: true,
      },
      name: 'ScheduleTypeID',
      type: 'select',
      label: 'Schedule Type',
      labelPlural: 'Schedule Types',
      labelIncludeCount: true,
      initialState: '-1',
      isRemoteFilter: false,
      isClearable: true,
      helpText: 'Filter by Schedule Type',
      position: 'top',
    },
    {
      connection: {
        options: [
          { value: 1, label: 'Yes' },
          { value: -1, label: 'No' },
        ],
      },
      name: 'Active',
      type: 'select',
      label: 'Active',
      initialState: -1,
      isRemoteFilter: false,
      isClearable: true,
      helpText: 'Hide Inactive Subscriptions',
      position: 'top',
    },
    {
      connection: {
        options: [
          { value: 1, label: 'Show Deleted' },
          { value: 0, label: 'Hide Deleted' },
        ],
      },
      name: 'Disabled',
      type: 'select',
      label: 'Deleted',
      initialState: 0,
      isRemoteFilter: true,
      isClearable: true,
      position: 'dropdown',
    },
  ],
  list: {
    connection: {
      data: {
        datasource: 'TRG-55UA-DW1',
        statement: "EXEC ISODataWarehouse.ISO.RPS_GetSubscriptions @ReportID = '{reportId}', @EmailAddress = '{email}', @SubscriptionID = {subscriptionId}, @Data_Scheme = 0, @Disabled = {disabled}",
        cache: false,
      },
      dimensions: {
        datasource: 'TRG-55UA-DW1',
        statement: 'EXEC ISODataWarehouse.ISO.RPS_GetGlobalDimensions',
        cache: true,
      },
      lookup: {
        datasource: 'TRG-55UA-DW1',
        statement: "EXEC ISODataWarehouse.ISO.RPS_GetSubscriptions @ReportID = '{reportId}', @EmailAddress = '{email}', @SubscriptionID = {subscriptionId}, @Data_Scheme = 1, @Disabled = {disabled}",
        cache: true,
      },
    },
    edit: {
      mode: 'click',
      blurToSave: true,
    },
    pagination: {
      custom: true,
      sizePerPageList: [
        { text: '7', value: 7 },
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: 'All', value: 999 },
      ],
      sizePerPage: 7,
      totalSize: 0,
    },
    compact: false,
    index: 'SubscriptionID',
  },
  save: {
    connection: {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_ManageISOReportSubscription ',
    },
    fields: [],
  },
};

export default config;
