import React from 'react';
import { getEditType } from '../../../const';

const ChangeField = ({
  field,
  error,
  touched,
  value,
  ...props
}) => {
  const TagName = getEditType(field.dataType);

  return (
    <>
      <label
        className={field.required ? 'required' : ''}
        htmlFor={field.name}
      >
        {field.label}
      </label>
      <TagName
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        touched={touched}
        error={error}
        options={field.options}
        initialValue={value}
      />
      {error && touched
      && (
      <div className="fv-plugins-message-container">
        <div className="fv-help-block">{error}</div>
      </div>
      )}
    </>
  );
};

export default ChangeField;
