import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _, { isUndefined, join, map } from 'lodash';
import ReactSelect from 'react-select';
import { Overlay, Tooltip } from 'react-bootstrap';
import useStateTimeout from '../../pages/subscriptions/hooks/useStateTimeout';

const styles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#f7f9ff',
    margin: 0,
    color: '#333',
    whiteSpace: 'nowrap',
    minWidth: 'min-content',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#f7f9ff',
    boxShadow: 'none',
    color: '#333',
    minWidth: '150px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#187DE4',
    color: '#fff',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fff',
    minWidth: '20px',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: '#3699ff',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingLeft: '2px',
    paddingRight: '2px',
    marginLeft: '3px',
  }),
};

const getCSSClasses = (touched, errors) => {
  const classes = ['select2-select'];

  if (touched && errors) {
    classes.push('is-invalid');
  }
  if (touched && !errors) {
    classes.push('is-valid');
  }
  return classes.join(' ');
};

const Select = ({
  options,
  onChange: onChangeProp,
  name,
  helpText,
  initialValue,
  onBlur = () => {},
  touched,
  error,
  clearValue,
  isLoading = false,
  showTooltip: allowTooltip = false,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [showToolTip, onShowTooltip, onHideTooltip] = useStateTimeout(false, 500);

  const target = useRef(null);

  const valueOption = useMemo(() => (_.isUndefined(value) ? []
    : _.chain(value)
      .split(',')
      .map((optionVal) => _.find(options, (option) => _.toString(option.value) === _.toString(optionVal)))
      .value()), [value, options]);

  const optionLabels = useMemo(() => join(map(valueOption, (option) => option?.label), ', '), [valueOption]);

  const onChange = useCallback((optionsSelected) => {
    let optionsHook = optionsSelected;

    if (_.isEmpty(optionsSelected) && !_.isUndefined(clearValue)) {
      // Get the clear value if we have defined one.
      optionsHook = clearValue;
    }
    if (_.isEmpty(optionsSelected) && _.isFunction(clearValue)) {
      // Get the clear value if we have defined one.
      optionsHook = clearValue();
    } else if (_.isArray(optionsSelected) && _.toString(_.last(optionsSelected).value) === '-1') {
      // If we added 'All', clear the other values.
      optionsHook = clearValue();
    } else if (_.isArray(optionsSelected) && optionsSelected.length > 1) {
      // Otherwise, remove the 'All' value if we have one.
      optionsHook = _.filter(optionsSelected, (option) => _.toString(option.value) !== '-1');
    }

    const optionsJoin = optionsHook && _.join(_.map(_.isArray(optionsHook) ? optionsHook : [optionsHook], (option) => option.value), ',');
    setValue(optionsJoin);
    onChangeProp(optionsJoin);
  }, [clearValue, onChangeProp]);

  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <div
      onBlur={() => {
        onHideTooltip();
        onBlur({ target: { name, value } });
      }}
      onMouseEnter={() => onShowTooltip(true)}
      onMouseLeave={onHideTooltip}
      className={getCSSClasses(touched, error)}
    >
      <Overlay target={target.current} show={!isUndefined(valueOption[0]) && allowTooltip && showToolTip} placement="bottom">
        <Tooltip id={`filter-tooltip-${name}`}>
          {optionLabels ?? ''}
        </Tooltip>
      </Overlay>
      <div ref={target}>
        <ReactSelect
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          isClearable={!_.isUndefined(clearValue) && value !== clearValue ? true : undefined}
          name={name}
          styles={styles}
          options={options}
          value={valueOption}
          isLoading={isLoading}
          touched={touched}
          error={error}
          onMenuOpen={onHideTooltip}
          onChange={onChange}
          menuPlacement="bottom"
          menuPosition="fixed"
          menuShouldScrollIntoView={false}
          menuPortalTarget={document.body}
          captureMenuScroll
        />
      </div>
      {helpText && <small className="form-text text-muted">{helpText}</small>}
    </div>
  );
};

export default Select;
