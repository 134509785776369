import { Spinner } from 'react-bootstrap';
import React from 'react';

const Button = ({
  variant = 'primary',
  size = 'md',
  disabled = false,
  showSpinner = false,
  onClick = () => {},
  style = {},
  className = '',
  children,
  ...props
}) => (
  <button
    type="button"
    className={`btn btn-${variant} btn-${size} btn-elevate ${className}`}
    disabled={disabled}
    onClick={onClick}
    style={style}
    {...props}
  >
    {showSpinner && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )}
    {' '}
    {children}
  </button>
);

export default Button;
