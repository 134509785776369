import { createSlice } from '@reduxjs/toolkit';
import config from '../config';
import { KTUtil } from '../../../_metronic/_assets/js/components/util';

const ListReducer = createSlice({
  name: 'list',
  initialState: config.list,
  reducers: {
    setList: (state, action) => {
      state.name = action.payload.name;
    },
    setPaginationSizePerPage: (state, action) => {
      state.pagination.sizePerPage = action.payload;
    },
    toggleListCompact: (state) => {
      state.compact = !state.compact;
      KTUtil.toggleClass(KTUtil.getBody(), 'compact-view');
    },
    loadListPreferences: (state, action) => {
      state.pagination.sizePerPage = action?.payload?.sizePerPage ?? state.pagination.sizePerPage;
      state.compact = action?.payload?.compact ?? state.compact;

      if (state.compact) {
        KTUtil.addClass(KTUtil.getBody(), 'compact-view');
      }
    },
  },
});

export default ListReducer.reducer;
export const {
  setList, loadListPreferences, setPaginationSizePerPage, toggleListCompact,
} = ListReducer.actions;
