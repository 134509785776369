import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from 'src/_modules/Auth/_redux/authRedux';
import {
  globalDimensionsReducer,
  isoReportSchemeReducer,
  isoReportsReducer,
  ssrsReportsReducer,
  configReducer,
  LayoutReducer,
} from './index';
import {
  watchGlobalDimensions,
  watchISOReportScheme,
  watchISOReports,
  watchSSRSReports,
  watchConfig,
} from './sagas';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  layout: LayoutReducer,
  globalDimensions: globalDimensionsReducer,
  isoReportScheme: isoReportSchemeReducer,
  isoReports: isoReportsReducer,
  ssrsReports: ssrsReportsReducer,
  config: configReducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    watchGlobalDimensions(),
    watchISOReportScheme(),
    watchISOReports(),
    watchSSRSReports(),
    watchConfig(),
  ]);
}
