import _ from 'lodash';

/**
 * Replace a string given an array of values to replace with.
 */
// eslint-disable-next-line import/prefer-default-export
export const replaceMergeFields = (array, string) => {
  let stringReplace = string;
  _.each(array, (value, key) => {
    stringReplace = _.replace(stringReplace, new RegExp(`{${key}}`, 'gi'), value !== '' ? value : 'null');
  });
  return _.replace(stringReplace, /'null'/g, 'null');
};

/**
 * Provide a means of allowing js to wait for x milliseconds
 */
export const sleep = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });
