import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';
import ListGroupDualBox from '../../../components/list-group/ListGroupDualBox';
import { getDataMetaList, getDataMetaNotList } from '../selectors/DataMetaSelector';

const ListActionsListColumns = ({
  onHide = () => {},
  show = false,
  onSave = () => {},
  onReset = () => {},
  onChange = () => {},
}) => {
  const columnsVisibleInit = useSelector(getDataMetaList);
  const columnsHiddenInit = useSelector(getDataMetaNotList);

  const [columnsVisible, setColumnsVisible] = useState(columnsHiddenInit);
  const [columnsHidden, setColumnsHidden] = useState(columnsHiddenInit);

  useEffect(() => {
    setColumnsVisible(columnsVisibleInit);
    setColumnsHidden(columnsHiddenInit);
  }, [columnsHiddenInit, columnsVisibleInit]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Table Columns
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroupDualBox
          box1Title="Hidden Columns"
          box2Title="Visible Columns"
          box1Items={columnsHidden}
          box2Items={columnsVisible}
          onChange={(hidden, visible) => {
            setColumnsHidden(hidden);
            setColumnsVisible(visible);
            onChange(visible);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onReset}>
          <i className="fas fa-undo small" />
          {' '}
          Reset
        </Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button onClick={() => onSave(columnsVisible)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ListActionsListColumns;
