import axios from 'axios';
import _ from 'lodash';
import { KTCookie } from '../../../_metronic/_assets/js/components/cookie';

axios.defaults.withCredentials = true;

export const LOGIN_URL = `${window.env.API_URL}/auth/login`;
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const ME_URL = `${window.env.API_URL}/auth/me`;

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, {
    email, fullname, username, password,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  const authToken = KTCookie.getCookie('jwt');

  // If we don't have an auth token, simply return.
  if (_.isEmpty(authToken)) {
    return undefined;
  }

  // Decode the payload of the JWT token.
  const { user, exp } = JSON.parse(atob(_.split(authToken, '.')[1]));

  if (new Date(exp * 1000) < Date.now()) {
    return undefined;
  }
  return JSON.parse(user);
}

export function isUserTokenExpired() {
  const authToken = KTCookie.getCookie('jwt');

  // If we don't have an auth token, simply return.
  if (_.isEmpty(authToken)) {
    return false;
  }

  // Decode the payload of the JWT token.
  const { exp } = JSON.parse(atob(_.split(authToken, '.')[1]));
  return new Date(exp * 1000) < Date.now();
}
