import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _, { map } from 'lodash';
import QueryService from '../../../services/QueryService';
import { getSaveQuery } from '../selectors/SaveSelector';
import { replaceConnection } from '../selectors/FilterSelector';
import { getDataConnection } from '../selectors/ListSelector';
import { getData } from './DataReducer';
import config from '../config';

export const entityDataModifiedAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => a.label.localeCompare(b.label),
});

export const saveDataModified = createAsyncThunk(
  'save/modified',
  async (none, { getState, dispatch }) => {
    // const rows = entityDataModifiedAdapter.getSelectors().selectAll(getState().dataModified);
    // add in the logged in user to the rows collection so we can write the username to the DB as the changeuser
    const rows = map(entityDataModifiedAdapter.getSelectors().selectAll(getState().dataModified), (row) => {
      const row1 = { ...row };
      row1.ChangeUser = localStorage.getItem('userName');
      return row1;
    });
    // Save data
    const ids = map(await Promise.all(
      _.map(rows, (row) => QueryService.run(getSaveQuery(getState(), row))),
    ), (row) => row[0]['']);

    // Copy the ids into the original rows so we can highlight new rows.
    for (let i = 0; i < ids.length; i += 1) {
      rows[i] = {
        ...rows[i],
        id: ids[0],
        [config.list.index]: ids[0],
      };
    }

    // Get new data
    const connection = replaceConnection(getState(), getDataConnection(getState()));
    await dispatch(getData(connection));
    return rows;
  },
);

const DataModifiedReducer = createSlice({
  name: 'dataModified',
  initialState: {
    ...entityDataModifiedAdapter.getInitialState(),
    entitiesHidden: false,
    isSaving: false,
    saveError: false,
    saveMessage: '',
    savedTimeStamp: 0,
    savedRows: [],
  },
  reducers: {
    setDataModified: (state, action) => entityDataModifiedAdapter.upsertMany(state, action.payload),
    addDataModified: (state, action) => entityDataModifiedAdapter.upsertOne(state, action.payload),
    addDataModifiedHidden: (state, action) => {
      state.entitiesHidden = true;
      entityDataModifiedAdapter.upsertOne(state, action.payload);
    },
    removeDataModified: (state, action) => {
      entityDataModifiedAdapter.removeOne(state, action.payload);
    },
    clearDataModified: (state) => entityDataModifiedAdapter.removeAll(state),
  },
  extraReducers: {
    [saveDataModified.pending]: (state) => {
      state.isSaving = true;
    },
    [saveDataModified.fulfilled]: (state, { payload }) => {
      state.isSaving = false;
      state.saveError = false;
      state.saveMessage = 'The subscription(s) was saved successfully';
      state.entitiesHidden = false;
      state.savedTimeStamp = Date.now();
      state.savedRows = payload;
      entityDataModifiedAdapter.removeAll(state);
    },
    [saveDataModified.rejected]: (state, { error }) => {
      state.isSaving = false;
      state.saveError = true;
      state.saveMessage = 'An unknown error occurred when saving';
      state.entitiesHidden = false;
      state.savedTimeStamp = 0;
      state.savedRows = [];
      console.error(error);
    },
  },
});

export default DataModifiedReducer.reducer;
export const {
  setDataModified, addDataModified, clearDataModified, addDataModifiedHidden, removeDataModified,
} = DataModifiedReducer.actions;
