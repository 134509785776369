import { useDispatch, useSelector } from 'react-redux';
import {
  getFilterConnectionsKeyValue,
  getObjectFilterReplacement,
  isFiltersInitialised,
} from '../selectors/FilterSelector';
import { getDataFilter } from '../reducers/DataFilterReducer';
import useDeepEffect from '../hooks/useDeepEffect';

const DataFilterProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isInitialised = useSelector(isFiltersInitialised);
  const connections = useSelector((state) => getObjectFilterReplacement(state, getFilterConnectionsKeyValue(state)));

  useDeepEffect(() => {
    if (isInitialised) {
      dispatch(getDataFilter(connections));
    }
  }, [dispatch, isInitialised, connections]);

  return children;
};

export default DataFilterProvider;
