import { put, takeLatest, call } from 'redux-saga/effects';
import QueryService from '../../services/QueryService';
import {
  fetchGlobalDimensionsStart,
  fetchGlobalDimensionsSuccess,
  fetchGlobalDimensionsFailure,
} from './globalDimensionsSlice';

function* fetchGlobalDimensions() {
  try {
    const query = {
      datasource: 'TRG-55UA-DW1',
      statement: 'EXEC ISODataWarehouse.ISO.RPS_GetGlobalDimensions',
      cache: true,
    };

    const result = yield call(QueryService.run, query);

    yield put(fetchGlobalDimensionsSuccess(result));
  } catch (error) {
    yield put(fetchGlobalDimensionsFailure(error.message));
  }
}

export default function* watchGlobalDimensions() {
  yield takeLatest(fetchGlobalDimensionsStart.type, fetchGlobalDimensions);
}
