import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import React, { useState } from 'react';
import { KTCookie } from '../../_metronic/_assets/js/components/cookie';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import PageService from '../../services/PageService';

const Favorite = () => {
  const basePath = PageService.getBasePath();
  const [isFavourite, setFavourite] = useState();

  // Fix issue where the pathname isn't returned on initial redirect.
  if (KTCookie.getCookie('favourite') === basePath && !isFavourite) {
    setFavourite(true);
  } else if (KTCookie.getCookie('favourite') !== basePath && isFavourite) {
    setFavourite(false);
  }

  return (
    <div className="topbar-item">

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="favourites-tooltip">Set page as favourite</Tooltip>}
      >
        <button
          className="btn btn-icon btn-clean btn-lg mr-1"
          type="button"
          onClick={() => {
            if (isFavourite) {
              KTCookie.setCookie('favourite', '/dashboard');
              setFavourite(false);
            } else {
              KTCookie.setCookie('favourite', basePath);
              setFavourite(true);
            }
          }}
        >
          <span className={`svg-icon svg-icon-xl ${isFavourite ? 'svg-icon-warning' : 'svg-icon-dark-25'}`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Star.svg')} />
          </span>
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default Favorite;
