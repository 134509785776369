import React from "react";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import Configure from "../../../../components/topbar/Configure";
import Favorite from "../../../../components/topbar/Favorite";

export function Topbar() {
  
  return (
    <div className="topbar">
      <Favorite/>
      <Configure/>
      <QuickUserToggler />
    </div>
  );
}
