/* eslint-disable */
"use strict";

import KTToggle from "./../../components/toggle.js";
import KTLayoutStickyCard from "./sticky-card.js";
import KTLayoutHeaderMenu from "./header-menu.js";
import KTLayoutAsideMenu from "./aside-menu.js";
import { KTUtil } from "./../../components/util.js";
import {each} from "lodash";
import { setLayoutMenuMinimized } from "src/redux/layout/LayoutReducer.js";

var KTLayoutAsideToggle = function() {
    // Private properties
    var _body;
    var _element;
    var _toggleObject;

	// Initialize
	var _init = function() {
		_toggleObject = new KTToggle(_element, _body, {
			targetState: 'aside-minimize',
			toggleState: 'active'
		});

		_toggleObject.on('toggle', function(toggle) {
			// Update sticky card
			if (typeof KTLayoutStickyCard !== 'undefined') {
					KTLayoutStickyCard.update();
			}

			// Pause header menu dropdowns
			if (typeof KTLayoutHeaderMenu !== 'undefined') {
					KTLayoutHeaderMenu.pauseDropdownHover(800);
			}

			// Pause aside menu dropdowns
			if (typeof KTLayoutAsideMenu !== 'undefined') {
					KTLayoutAsideMenu.pauseDropdownHover(800);
			}
			
			store.dispatch(setLayoutMenuMinimized(toggle.getState() === 'on'));
		});

		_toggleObject.on('beforeToggle', function(toggle) {
			if (KTUtil.hasClass(_body, 'aside-minimize') === false && KTUtil.hasClass(_body, 'aside-minimize-hover')) {
				KTUtil.removeClass(_body, 'aside-minimize-hover');
			}
		});
	}

    // Public methods
	return {
		init: function(cls) {
			const elements = KTUtil.getByClass(cls);
			_body = KTUtil.getBody();

			if (!elements) {
					return;
			}
			
			// Initialize
			each(elements, (element) => {
				_element = element;
				_init();
			});
		},

		getElements: function() {
				return _element;
		},

		getToggle: function() {
			return _toggleObject;
		},

		onToggle: function(handler) {
			if (typeof _toggleObject.element !== 'undefined') {
				_toggleObject.on('toggle', handler);
			}
		}
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = KTLayoutAsideToggle;
}

export default KTLayoutAsideToggle;
