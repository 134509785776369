import { useDispatch, useSelector } from 'react-redux';
import { each } from 'lodash';
import { getListDimensionConnection, getListLookupConnection } from '../selectors/ListSelector';
import { getDataMeta } from '../reducers/DataMetaReducer';
import { isDataMetaInitialised, replaceConnection } from '../selectors/DataMetaSelector';
import useDeepEffect from '../hooks/useDeepEffect';
import PageService from '../../../services/PageService';
import store from '../store';
import { isFilterPreloadSelected } from '../selectors/FilterSelector';
import { getData } from '../selectors/DataSelector';
import QueryService from '../../../services/QueryService';
import { actions as authActions } from '../../../_modules/Auth';

const DataMetaProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isInitialised = useSelector(isDataMetaInitialised);

  const connections = useSelector(
    (state) => ({
      lookup: replaceConnection(state, getListLookupConnection(state), PageService.getObjectId()),
      dimension: replaceConnection(state, getListDimensionConnection(state), PageService.getObjectId()),
    }),
  );

  useDeepEffect(() => {
    /**
     * If there is a filter selected, that has the config value of preloadMetaData,
     * then preload the meta data relating to all the rows.
     *
     * Add a cap of 15 rows as a safe guard for fetching too much.
     */
    const preloadRows = setTimeout(() => {
      const state = store.getState();
      const data = getData(state).slice(0, 15);

      if (!isFilterPreloadSelected(state)) {
        return;
      }

      each(data, (row) => {
        const query = replaceConnection(state, getListLookupConnection(state), row.id);

        if (query.cache && !QueryService.isCached(query)) {
          QueryService.run(query, () => dispatch(authActions.expire())).catch((e) => console.warn(e));
        }
      });
    }, 500);

    if (isInitialised) {
      dispatch(getDataMeta(connections)).then(preloadRows);
    }
  }, [connections, dispatch, isInitialised]);

  return children;
};

export default DataMetaProvider;
