import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterPreferences } from '../selectors/FilterSelector';
import { setPreferences } from '../reducers/PreferenceReducer';
import { getMetaDataPreferences } from '../selectors/DataMetaSelector';
import { loadMetaDataPreferences } from '../reducers/DataMetaReducer';
import { getPreferencesStoredByName } from '../selectors/PreferenceSelector';
import { loadFilterPreferences } from '../reducers/FilterReducer';
import useDeepEffect from '../hooks/useDeepEffect';
import { loadListPreferences } from '../reducers/ListReducer';
import { getListPreferences } from '../selectors/ListSelector';

const PreferenceProvider = ({ children }) => {
  const dispatch = useDispatch();

  const filterPreferences = useSelector(getFilterPreferences);
  const listPreferences = useSelector(getListPreferences);
  const metaDataPreferences = useSelector(getMetaDataPreferences);

  const firstUpdate = useRef(true);

  // ------------------------------------
  // Config List Preferences
  // ------------------------------------
  useDeepEffect(() => {
    dispatch(loadListPreferences(getPreferencesStoredByName('list')));
  }, [dispatch]);

  useDeepEffect(() => {
    if (!firstUpdate.current) {
      dispatch(setPreferences({ name: 'list', value: listPreferences }));
    }
  }, [dispatch, listPreferences]);

  // ------------------------------------
  // Filter Preferences
  // ------------------------------------
  useDeepEffect(() => {
    dispatch(loadFilterPreferences(getPreferencesStoredByName('filter')));
  }, [dispatch]);

  useDeepEffect(() => {
    if (!firstUpdate.current) {
      dispatch(setPreferences({ name: 'filter', value: filterPreferences }));
    }
  }, [dispatch, filterPreferences]);

  // ------------------------------------
  // Column Preferences
  // ------------------------------------
  useDeepEffect(() => {
    if (!firstUpdate.current) {
      dispatch(setPreferences({ name: 'meta-data', value: metaDataPreferences }));
    }
  }, [metaDataPreferences, dispatch]);

  useDeepEffect(() => {
    dispatch(loadMetaDataPreferences(getPreferencesStoredByName('meta-data')));
  }, [dispatch]);

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  return children;
};

export default PreferenceProvider;
