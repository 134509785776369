/* eslint no-use-before-define: 0 */
/* eslint react/jsx-filename-extension: 0 */
/* eslint react/react-in-jsx-scope: 0 */
import { createSelector } from '@reduxjs/toolkit';
import _, {
  chain, each, filter, has, includes, isEmpty, map,
} from 'lodash';
import React from 'react';
import { customFilter, textFilter } from 'react-bootstrap-table2-filter';
import { entityDataMetaAdapter } from '../reducers/DataMetaReducer';
import { sortCaret } from '../../../_metronic/_helpers';
import { getEditType } from '../../../const';
import ChangedColumnFormatter from '../../../components/column-formatters/ChangedColumnFormatter';
import ActionsColumnFormatter from '../../../components/column-formatters/ActionsColumnFormatter';
import ColumnTypeCSVDetector from '../../../components/type-detectors/ColumnTypeCSVDetector';
import ColumnTypeDetector from '../../../components/type-detectors/ColumnTypeDetector';
import Button from '../../../components/Button';
import { replaceFilterMergeFields } from './FilterSelector';
import { replaceDataMergeFields } from './DataSelector';
import { replacePageIdMergeFields } from './ListSelector';

export const {
  selectById: getDataMetaByName,
  selectIds: getDataMetaIds,
  selectEntities: getDataMetaKeyValue,
  selectAll: getDataMeta,
  selectTotal: getDataMetaCount,
} = entityDataMetaAdapter.getSelectors((state) => state.dataMeta);

export const isDataMetaLoading = (state) => state.dataMeta.isLoading;
export const isDataMetaInitialised = (state) => state.dataMeta.isInitialised;
export const getColumnListSelection = (state) => state.dataMeta.columnListSelection;
export const getColumnListSelectionKeyValue = (state) => Object.assign({}, ...map(getColumnListSelection(state), (column) => ({ [column.name]: column })));
export const getColumnExportSelection = (state) => state.dataMeta.columnExportSelection;
export const getColumnExportSelectionKeyValue = (state) => Object.assign({}, ...map(getColumnExportSelection(state), (column) => ({ [column.name]: column })));

/**
 * Replace a connection with statement with all the filter, and row values
 */
export const replaceConnection = (state, connection, rowId) => ({
  ...connection,
  statement: replaceFilterMergeFields(state, replaceDataMergeFields(state, rowId, replacePageIdMergeFields(connection.statement))),
});

/**
 * Returns a list of user settings that we want stored and to persist across page refreshes.
 */
export const getMetaDataPreferences = (state) => ({
  columnList: getColumnListSelectionKeyValue(state),
  columnExport: getColumnExportSelectionKeyValue(state),
});

/**
 * Return a list of columns that will be displayed in a list and are either:
 * A. Selected by the user to display (incl. order)
 * B. Configured to be displayed by the data source
 */
export const getDataMetaList = createSelector(
  [getDataMeta, getColumnListSelectionKeyValue],
  (columns, columnsSelected) => {
    if (isEmpty(columnsSelected)) {
      return filter(columns, (column) => column.displayList);
    }
    return chain(columns)
      .filter((column) => has(columnsSelected, column.name))
      .map((column) => ({
        ...column,
        sortOrder: columnsSelected[column.name].sortOrder ?? column.sortOrder,
      }))
      .sortBy('sortOrder')
      .value();
  }
);

/**
 * Return a list of columns that will be not displayed in a list.
 */
export const getDataMetaNotList = createSelector(
  [getDataMeta, getDataMetaList],
  (columns, columnsList) => filter(columns, (column) => !includes(map(columnsList, 'name'), column.name))
);

/**
 * Return a list of columns that will be displayed in a edit
 * and are configured to be displayed by the data source.
 */
export const getDataMetaEdit = createSelector(
  [getDataMeta],
  (columns) => chain(columns)
    .filter('displayEdit')
    .map((column) => ({ ...column, width: column.displayEditWidth }))
    .value(),
);

/**
 * Return a list of columns that will be displayed in an export and are either:
 * A. Selected by the user to display (incl. order)
 * B. Configured to be displayed by the data source
 */
export const getDataMetaExport = createSelector(
  [getDataMeta, getColumnExportSelectionKeyValue],
  (columns, columnsSelected) => {
    if (isEmpty(columnsSelected)) {
      return filter(columns, (column) => column.displayExport);
    }
    return chain(columns)
      .filter((column) => has(columnsSelected, column.name))
      .map((column) => ({
        ...column,
        sortOrder: columnsSelected[column.name].sortOrder ?? column.sortOrder,
      }))
      .sortBy('sortOrder')
      .value();
  },
);

/**
 * Return a list of columns that will be not displayed in an export.
 */
export const getDataMetaNotExport = createSelector(
  [getDataMeta, getDataMetaExport],
  (columns, columnsExport) => filter(columns, (column) => !includes(map(columnsExport, 'name'), column.name)),
);

/**
 * Return a list of columns that will be displayed in a create
 * and are configured to be displayed by the data source.
 */
export const getDataMetaCreate = createSelector(
  [getDataMeta],
  (columns) => chain(columns)
    .filter('displayCreate')
    .map((column) => ({ ...column, width: column.displayCreateWidth }))
    .value(),
);

/**
 * A list of column filters to provide interaction between the clear filters button and the inputs themselves.
 */
const columnFilters = {};

/**
 * Returns a list of columns to be used by a table.
 */
export const getColumnsList = createSelector(
  [getDataMetaList],
  (columns) => {
    if (columns.length === 0) {
      return [];
    }

    const columnsReturn = _.chain(columns)
      .map((column) => ({
        dataField: column.name,
        text: column.label,
        sort: true,
        sortCaret,
        editable: column.displayEdit,
        dataType: column.dataType,
        editorRenderer: (editorProps, value, row, columnEditor) => {
          const TagName = getEditType(columnEditor.dataType);
          editorProps.ref({ getValue: () => value });

          return (
            <ChangedColumnFormatter row={row}>
              <TagName
                name="cell-edit"
                autoFocus
                options={editorProps.options}
                initialValue={editorProps.defaultValue}
                onBlur={editorProps.onBlur}
                onKeyDown={editorProps.onKeyDown}
                onChange={(valueNew) => {
                  editorProps.ref({ getValue: () => valueNew });
                  if (_.includes(['select', 'multiselect'], columnEditor.dataType)) {
                    editorProps.onBlur();
                  }
                }}
              />
            </ChangedColumnFormatter>
          );
        },
        editor: {
          options: column.options,
        },
        formatExtraData: column,
      }))
      // After the column is rendered, use the type detector so we can use the previous values.
      .map((column) => ({
        ...column,
        filter: textFilter({
          placeholder: 'Filter',
          getFilter: (filterFn) => {
            columnFilters[column.dataField] = filterFn;
          },
          onFilter: (filterVal, table) => {
            if (filterVal) {
              return _.filter(table, (row, rowIndex) => {
                const value = `${(new ColumnTypeCSVDetector(column)).format(row[column.dataField], row, rowIndex)}`;
                return value.toLowerCase().indexOf((`${filterVal}`).toLowerCase()) !== -1;
              });
            }
            return table;
          },
        }),
        sortFunc: (a, b, order) => {
          const aVal = (new ColumnTypeCSVDetector(column)).format(a, [a], 0);
          const bVal = (new ColumnTypeCSVDetector(column)).format(b, [b], 0);

          const comparator = (a1, b1) => (b1 === 'string' ? b1.localeCompare(a1) : (a1 > b1 ? -1 : a1 < b1 ? 1 : 0));
          return order === 'desc' ? comparator(aVal, bVal) : comparator(bVal, aVal);
        },
        formatter: (new ColumnTypeDetector(column)).getFormatter(),
      }))
      .value();

    // Add the action column at the beginning.
    return _.union([{
      dataField: 'action',
      text: 'Action',
      editable: false,
      formatter: ActionsColumnFormatter,
      filter: customFilter(),
      filterRenderer: () => (
        <Button
          className="table-clear px-2"
          variant="success"
          onClick={() => {
            each(columnFilters, (filterFn) => {
              filterFn('');
            });
          }}
        >
          Clear&nbsp;Filters
        </Button>
      ),
    }], columnsReturn);
  },
);

/**
 * Returns a list of fields to be used by a form.
 */
export const getColumnsCreate = createSelector(
  [getDataMetaCreate],
  (columns) => {
    // Don't render the columns if the dimensions and lookup are not present.
    if (_.isEmpty(columns)) { return {}; }

    return _.chain(columns)
      .map((column) => ({
        name: column.SchemaColumnName,
        label: column.DisplayName,
        placeholder: '',
        required: column.IsReq,
        dataType: column.dataType,
        options: column.dimensionsValueLabel,
        groupId: column.LayoutGroupID,
        groupName: column.LayoutGroupName,
      }))
      .groupBy('groupId')
      .value();
  },
);

/**
 * Returns a list of columns to be used when exporting a table.
 */
export const getColumnsExport = createSelector(
  [getDataMetaExport],
  (columns) => chain(columns)
    .map((column) => ({
      dataField: column.name,
      text: column.label,
      dataType: column.dataType,
      formatExtraData: column,
    }))
    // After the column is rendered, use the type detector so we can use the previous values.
    .map((column) => ({
      ...column,
      csvFormatter: (cell, row, rowIndex) => (new ColumnTypeCSVDetector(column)).format(cell, row, rowIndex),
    }))
    .value()
);
