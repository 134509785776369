import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import config from '../config';

export const entitySaveAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const SaveReducer = createSlice({
  name: 'save',
  initialState: {
    ...entitySaveAdapter.addMany(entitySaveAdapter.getInitialState(), []),
    ...omit(config.save, 'fields'),
  },
  reducers: {
    setSave: (state, action) => {
      state.connection = action.payload;
      entitySaveAdapter.addMany(state, action);
    },
    setFields: (state, action) => {
      state.fields = action.payload;
      entitySaveAdapter.addMany(state, action);
    },
  },
});

export default SaveReducer.reducer;
export const { setSave, setFields } = SaveReducer.actions;
