import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

const useDeepEffect = (callback, dependencies) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepEffect;
