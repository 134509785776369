import { find } from 'lodash';
import { entityPreferenceAdapter } from '../reducers/PreferenceReducer';
import { KTCookie } from '../../../_metronic/_assets/js/components/cookie';
import PageService from '../../../services/PageService';

export const {
  selectById: getPreferenceByName,
  selectIds: getPreferenceIds,
  selectEntities: getPreferencesKeyValue,
  selectAll: getPreferences,
  selectTotal: getPreferenceCount,
} = entityPreferenceAdapter.getSelectors((state) => state.preference);

export const getPreferencesStored = () => JSON.parse(KTCookie.getCookie(PageService.getKey()) || '[]');
export const getPreferencesStoredByName = (name) => find(getPreferencesStored(), { name })?.value;
