import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ssrsReports: [],
  loading: false,
  error: null,
};

const ssrsReportsSlice = createSlice({
  name: 'ssrsReports',
  initialState,
  reducers: {
    fetchSSRSReportsStart: (state) => {
      state.loading = true;
    },
    fetchSSRSReportsSuccess: (state, action) => {
      state.ssrsReports = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSSRSReportsFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchSSRSReportsStart,
  fetchSSRSReportsSuccess,
  fetchSSRSReportsFailure,
} = ssrsReportsSlice.actions;

export default ssrsReportsSlice.reducer;
