import { createSlice } from '@reduxjs/toolkit';
import config from '../config';

const CreateReducer = createSlice({
  name: 'create',
  initialState: config.create,
  reducers: {
    setCreate: (state, action) => {
      state.name = action.payload.name;
    },
  },
});

export default CreateReducer.reducer;
export const { setCreate } = CreateReducer.actions;
