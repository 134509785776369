/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import HomeIcon from '@material-ui/icons/Home';
import {People} from "@material-ui/icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import ConditionalWrapper from "../../../../../components/ConditionalWrapper";
import {useSelector} from "react-redux";
import {getLayoutMenuMinimized} from "src/redux/layout/LayoutSelector";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const menuMinimized = useSelector(getLayoutMenuMinimized);
  
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <ConditionalWrapper
            wrapper={children => <OverlayTrigger placement="bottom" overlay={<Tooltip id="menu-dashboard">Dashboard</Tooltip>}>{children}</OverlayTrigger>}
            condition={menuMinimized}
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <HomeIcon/>
            </span>
              <span className="menu-text">Home</span>
            </NavLink>
          </ConditionalWrapper>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">Reports</h4>
          <i className="menu-icon flaticon-more-v2"/>
        </li>
        <ConditionalWrapper
          wrapper={children => <OverlayTrigger placement="bottom" overlay={<Tooltip id="menu-subscriptions">Subscriptions</Tooltip>}>{children}</OverlayTrigger>}
          condition={menuMinimized}
        >
          <li
            className={`menu-item ${getMenuItemActive(
              "/subscriptions",
              false
            )} mb-2`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/subscriptions">
              <span className="svg-icon menu-icon">
                <People/>
              </span>
              <span className="menu-text">Subscriptions</span>
            </NavLink>
          </li>
        </ConditionalWrapper>
        <ConditionalWrapper
          wrapper={children => <OverlayTrigger placement="bottom" overlay={<Tooltip id="menu-reports">Reports</Tooltip>}>{children}</OverlayTrigger>}
          condition={menuMinimized}
        >
          <li
            className={`menu-item ${getMenuItemActive(
              "/reports",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/reports">
              <span className="svg-icon menu-icon">
                <People/>
              </span>
              <span className="menu-text">Reports</span>
            </NavLink>
          </li>
        </ConditionalWrapper>
      </ul>
    </>
  );
}
