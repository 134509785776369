/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControlLabel,
  FormControl as MUIFormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText } from "@mui/material";
import { useEffect } from 'react';

const GetControl = ({ control, errors, touched, readOnly = false }) => {
  // Get control types from global dimensions
  const controlTypes = useSelector((state) =>
    state.globalDimensions.dimensions.filter((dimension) => dimension.DimensionTypeID === 15)
  );

  // Get dimensions for current control (if applicable)
  const dimensions = useSelector((state) => {
    if (control.DimensionTypeID === null) {
      return [];
    }
    return state.globalDimensions.dimensions.filter(
      (dimension) => dimension.DimensionTypeID === control.DimensionTypeID
    );
  });

  const hasError = errors[control.name] && touched[control.name];
  const errorMessage = errors[control.name];
  
  // Local state for selected value (only used if component is a multi-select)
  const [multiselectValue, setMultiselectValue] = useState(() => {
    // Multiselect value is stored as "value1,value2,value3", so to get initial selection we parse that here
    if (control.value && typeof control.value === 'string') {
      const originalValues = control.value.split(",");
      const dims = dimensions.filter((x) => originalValues.indexOf(x.dim_key.toString()) > -1);
      return dims;
    }
    return [];
  });

  // Change event handler for multiselect component
  const handleMultiselectChange = (event) => {
    // Set local state value
    setMultiselectValue(event.target.value);

    // Call the formik onChange event, join values into a comma separated string
    control.onChange({
      target: {
        name: control.name,
        value: event.target.value.map(val => val.dim_key).join(','),
      },
    });
  };

  /**
   * 
   * @param {*} control The control to be rendered
   * @returns A JSX element representing the given control
   */
  const getControlByType = (control) => {
    // Special case for non-data driven control, specifically used for
    // rendering the ReportSelection component only on creating a report
    if (control.ControlTypeID === 0 && control.items) {
      // Custom filter options so we can filter the autocomplete by `name` and `id`
      const filterOptions = createFilterOptions({
        stringify: (option) => `${option.name} ${option.id}`
      });

      // renderOption property
      const renderOption = (props, option) => (
        <li {...props}>
          <span>{option.name}</span>
          <span style={{ marginLeft: '0.5em', fontSize: '0.8em', color: 'gray' }}>({option.id})</span>
        </li>
      );

      // renderInput property
      const renderInput = (params) => (
        <TextField
          {...params}
          label={control.name}
          disabled={control.disabled || readOnly}
          onChange={control.onChange}
          onBlur={control.onBlur}
          error={hasError}
          helperText={hasError && errorMessage}
        />
      );

      // onChange property
      const onChange = (event, newValue) => {
        control.onChange({
          target: {
            name: control.name,
            value: newValue ? newValue.id : '',
          },
        });
      }

      // Return the autocomplete component
      return (
        <Autocomplete
          options={control.items}
          getOptionLabel={(option) => option.name}
          filterOptions={filterOptions}
          renderOption={renderOption}
          renderInput={renderInput}
          value={control.value ? control.items.find((item) => item.id === control.value) : null}
          onChange={onChange}
          disabled={control.disabled || readOnly}
        />
      );
    }
    
    // Get control type
    const controlType = controlTypes.find(ct => ct.dim_key === control.ControlTypeID);

    // Render control based on control type DataCode
    switch (controlType?.DataCode) {
      case 'text':
        return <TextField
          name={control.name}
          label={control.DisplayName}
          value={control.value ?? ""}
          onChange={control.onChange}
          onBlur={(e) => {
            control.onBlur(e);
          }}
          disabled={control.disabled || readOnly}
          variant='outlined'
          fullWidth
          error={hasError}
          helperText={hasError && errorMessage}
        />;
      case 'textarea':
        return <TextField
          name={control.name}
          label={control.DisplayName}
          value={control.value}
          onChange={control.onChange}
          onBlur={control.onBlur}
          disabled={control.disabled || readOnly}
          multiline
          maxRows={4}
          fullWidth
          error={hasError}
          helperText={hasError && errorMessage}
        />;
      case 'checkbox':
        return <MUIFormControl error={hasError}>
          <FormControlLabel
            label={control.DisplayName}
            control={
              <Checkbox
                name={control.name}
                value={control.value}
                checked={control.value.toString() === 'true'}
                onChange={control.onChange}
                onBlur={control.onBlur}
                disabled={control.disabled || readOnly}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              />
            }
          />

          {hasError && (
            <FormHelperText>
              {errorMessage}
            </FormHelperText>
          )}  
        </MUIFormControl>
      case 'select':
        return (
          <Autocomplete
            options={dimensions}
            value={control.value ? dimensions.find((dimension) => dimension.DataCode === control.value) : null}
            disabled={control.disabled || readOnly}
            getOptionLabel={(option) => option.DataName}
            renderInput={(params) => (
              <TextField
                {...params}
                label={control.DisplayName}
                disabled={control.disabled || readOnly}
                onChange={control.onChange}
                onBlur={control.onBlur}
                error={hasError}
                helperText={hasError && errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              control.onChange({
                target: {
                  name: control.name,
                  value: newValue ? newValue.DataCode : '',
                },
              });
            }}
          />
        );
      case 'multiselect':
        return (
          <MUIFormControl fullWidth error={hasError}>
            <InputLabel id={control.name + '_label'}>
              {control.DisplayName}
            </InputLabel>

            <Select
              id={control.name}
              value={multiselectValue}
              onChange={handleMultiselectChange}
              onBlur={(e) => {
                control.onBlur(e);
              }}
              input={<OutlinedInput label={control.DisplayName} />}
              renderValue={(selected) => selected.map(item => item.DataName).join(', ')}
              disabled={control.disabled || readOnly}
              labelId={control.name + '_label'}
              multiple
            >
              {dimensions.map((dim) => (
                <MenuItem key={dim.dim_key} value={dim}>
                  <Checkbox checked={multiselectValue.indexOf(dim) > -1}/>
                  <ListItemText primary={dim.DataName} />
                </MenuItem>
              ))}
            </Select>

            {hasError && (
              <FormHelperText>
                {errorMessage}
              </FormHelperText>
            )}
          </MUIFormControl>
        );
      default:
        return null;
    }
  };

  return getControlByType(control);
};

export default GetControl;
