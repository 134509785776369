import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { each, isArray, values } from 'lodash';
import { KTCookie } from '../../../_metronic/_assets/js/components/cookie';
import PageService from '../../../services/PageService';

export const entityPreferenceAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
});

const PreferenceReducer = createSlice({
  name: 'preference',
  initialState: entityPreferenceAdapter.addMany(entityPreferenceAdapter.getInitialState(), JSON.parse(KTCookie.getCookie(PageService.getKey()) || '[]')),
  reducers: {
    setPreferences: (state, action) => {
      const entities = [];
      const payload = isArray(action.payload) ? action.payload : [action.payload];

      each(payload, (entity) => {
        entities.push({ name: entity.name, value: entity.value });
      });
      entityPreferenceAdapter.upsertMany(state, entities);
      KTCookie.setCookieAsync(PageService.getKey(), JSON.stringify(values(state.entities))).catch((e) => console.warn(e));
    },
  },
});

export default PreferenceReducer.reducer;
export const { setPreferences } = PreferenceReducer.actions;
