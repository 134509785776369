import React from 'react';
import DataFilterProvider from './providers/DataFilterProvider';
import DataMetaProvider from './providers/DataMetaProvider';
import DataProvider from './providers/DataProvider';

// eslint-disable-next-line react/display-name,func-names
const withData = (Component) => function () {
  return (
    <DataFilterProvider>
      <DataMetaProvider>
        <DataProvider>
          <Component />
        </DataProvider>
      </DataMetaProvider>
    </DataFilterProvider>
  );
};

export default withData;
