import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

import GetControl from '../../components/GetControl';

const ReportForm = ({ formikRef, onSubmit, columns, initialValues: initialValuesProp, validationSchema, readOnly = false }) => {
  // If initialValues is not set, generate initial values from form fields
  const initialValues = initialValuesProp || columns.reduce((acc, column) => {
    // Get default value
    let value = column.DefaultValue;
    // If boolean, value will be "1" or "0", so convert that to a bool
    if (column.ControlTypeID === 3) value = value === '1';
    // If null, convert to empty string
    if (value === null) value = '';

    return {
      ...acc,
      [column.SchemaColumnName]: value
    };
  }, {});

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const innerFormikRef = useRef(formik);

  useEffect(() => {
    innerFormikRef.current = formik;
  }, [formik, formikRef]);

  useImperativeHandle(formikRef, () => ({
    ...innerFormikRef.current
  }));

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {columns.map((column) => (
        <Grid item xs={12} key={column.SchemaColumnName}/* md={6} */>
          <GetControl
            control={{
              ControlTypeID: column.ControlTypeID,
              DimensionTypeID: column.DimensionTypeID,
              DisplayName: column.DisplayName,
              name: column.SchemaColumnName,
              value: formik.values[column.SchemaColumnName],
              onChange: formik.handleChange,
              onBlur: formik.handleBlur(column.SchemaColumnName),
              disabled: !column.IsEdit,
              items: column.items
            }}
            errors={formik.errors}
            touched={formik.touched}
            readOnly={readOnly}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportForm;
