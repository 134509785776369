import React from 'react';
import { Link } from 'react-router-dom';

const LinkMailTo = ({
  mailto, className, subject, ...props
}) => {
  const handleClick = (e) => {
    window.location = `mailto:${mailto}?subject=${subject || ''}`;
    e.preventDefault();
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to="#"
      className={className || ''}
      onClick={(e) => handleClick(e)}
    >
      {props.children}
    </Link>
  );
};

export default LinkMailTo;
