import React, { useState, useMemo, useCallback } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useModal from '../../components/modals/useModal';
import ReportModal from './ReportModal';
import CustomToolbar from './CustomToolbar';

const ReportPage = () => {
  const isoReportScheme = useSelector((state) => state.isoReportScheme.isoReportScheme);
  const isoReports = useSelector((state) => state.isoReports.items);

  const { isOpen, openModal, closeModal, renderModal, allowClose, setAllowClose } = useModal();
  const [editingReport, setEditingReport] = useState(null);

  const handleEditReport = useCallback((reportData) => {
    setEditingReport(reportData);
    openModal();
  }, [openModal]);

  const memoizedColumns = useMemo(() => {
    const actionCol = {
      field: 'actions',
      headerName: 'Actions',
      filterable: false,
      renderCell: (params) => (<IconButton color="primary" onClick={() => handleEditReport(params.row)}><i className="fa fa-pen" /></IconButton>),
    };

    return [actionCol, ...isoReportScheme.map(({ SchemaColumnName }) => ({ field: SchemaColumnName }))];
  }, [isoReportScheme, handleEditReport]);

  const CustomToolbarMemo = useMemo(() => <CustomToolbar onClick={openModal} />, [openModal]);

  return (
    <div className="d-flex flex-column" style={{ height: '100%' }}>
      <div className="row flex-grow-1">
        <div className="col-12 ag-theme-alpine" style={{ minHeight: '500px' }}>
          <DataGridPro
            rows={isoReports}
            columns={memoizedColumns}
            getRowId={(row) => row.ReportID}
            slots={{ toolbar: () => CustomToolbarMemo }}
            className="bg-white"
          />

          {
            renderModal && (
              <ReportModal
                isOpen={isOpen}
                closeModal={closeModal}
                exitedModal={() => {
                  setEditingReport(null);
                }}
                columns={isoReportScheme}
                initialValues={editingReport ?? undefined}
                allowClose={allowClose}
                setAllowClose={setAllowClose}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
