import _ from 'lodash';

const specialActions = [
  'list',
  'configure',
];

class PageService {
  /**
   * Get the path location, supporting both hash and browser based routing.
   *
   * @return {string}
   */
  static getPathName() {
    if (window.location.hash !== '') {
      return (window.location.hash ?? '#').substring(1);
    }
    return window.location.pathname.substring(1);
  }

  /**
   * Return the title of the page we want to display
   *
   * @return {string}
   */
  static getTitle() {
    return (
      _.chain([this.getVerb(), this.getAction()])
        .map((path) => _.upperFirst(path))
        .join(' ')
        .value()
    );
  }

  /**
   * Get the id of the object we are editing if one is available.
   *
   * @return {number|undefined}
   */
  static getObjectId() {
    const pathname = this.getPathName();
    const id = Number.parseInt(pathname.split('/')[2], 10);
    return !Number.isNaN(id) ? id : undefined;
  }

  /**
   * Get the primary path of the page
   * E.g. Subscription, Product, Customer
   *
   * @return {string}
   */
  static getBasePath() {
    const pathname = this.getPathName();
    return pathname.substring(0, pathname.indexOf('/', 1));
  }

  /**
   * Get the secondary path of the page
   * E.g. Edit, List
   *
   * @return {string}
   */
  static getSecondPath() {
    const pathname = this.getPathName();
    return pathname.substring(pathname.lastIndexOf('/'));
  }

  /**
   * Determines if a modal is open based on the path.
   *
   * @return {boolean}
   */
  static isModalOpen() {
    return typeof this.getObjectId() !== 'undefined';
  }

  /**
   * Get the action of the page
   * E.g. Edit, List
   *
   * @return {string}
   */
  static getAction() {
    return _.find(specialActions, (specialAction) => this.getPathName().indexOf(specialAction) !== -1) || specialActions[0];
  }

  /**
   * Get the verb of the path indicating the object we are working with
   * E.g. Subscription, Product, Customer
   *
   * @return {string}
   */
  static getVerb() {
    return this.getBasePath().substr(1);
  }

  /**
   * Return a unique key representing the path.
   * This is useful when we are wanting to save and store data in relations to a particular page.
   *
   * @return {string}
   */
  static getKey() {
    return this.getVerb();
  }

  /**
   * Get the title of the page, with the action included.
   * E.g. Subscription Edit
   *
   * @return {string}
   */
  static getTitleAction() {
    const pathname = this.getPathName();
    const action = pathname.split('/')[2] ?? '';
    let verb = pathname.split('/')[1] ?? '';

    // Make the title singular
    if (verb.substring(verb.length - 1) === 's') {
      verb = verb.substring(0, verb.length - 1);
    }

    return (
      _.chain([action, verb])
        .map((path) => _.upperFirst(path))
        .join(' ')
        .value()
    );
  }

  /**
   * Returns the environment based on the current hostname.
   *
   * Note: This is the only way to achieve the detection currently as the static
   * files are first deployed to UAT, and those exact build files are then deployed to production.
   */
  static getEnvironment() {
    switch (window.location.hostname) {
      case 'localhost':
        return 'dev';
      case 'rp-uat.iso.co.nz':
        return 'uat';
      case 'rp.iso.co.nz':
        return 'prod';
      default:
        return 'unk';
    }
  }

  /**
   * Returns true or false if the current environment is production based
   * on the hostname.
   *
   * @return {boolean}
   */
  static isProd() {
    return this.getEnvironment() === 'prod';
  }
}

export default PageService;
