import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { Grid, Typography, Box } from '@mui/material';

import GetControl from '../../components/GetControl';
import { ControlTypes } from './controlTypes';
import { parseDDRSValues } from './util';

const DDRSForm = ({ formikRef, onSubmit, columns, groups, initialValues: initialValuesProp, validationSchema }) => {
  // If initialValues is not set, generate initial values from form fields
  const initialValues = initialValuesProp || columns.reduce((acc, column) => {
    // Get default value
    let value = column.DefaultValue;
    // If boolean, value will be "1" or "0", so convert that to a bool
    if (column.ControlTypeID === 3) value = value === '1';
    // If null, convert to empty string
    if (value === null) value = '';

    return {
      ...acc,
      [column.SchemaColumnName]: value
    };
  }, {});

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(parseDDRSValues(values, groups));
    },
  });

  const innerFormikRef = useRef(formik);

  useEffect(() => {
    innerFormikRef.current = formik;
  }, [formik, formikRef]);

  useImperativeHandle(formikRef, () => ({
    ...innerFormikRef.current
  }));

  const renderGroup = (group) => {
    const id = group.find((column) => column.ControlTypeID === ControlTypes.DDRS_ID);
    const lastprocessed = group.find((column) => column.ControlTypeID === ControlTypes.DDRS_Member);
    const processorder = group.find((column) => column.ControlTypeID === ControlTypes.text);

    return (
      <Grid item xs={12} key={id.SchemaColumnName}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">{id.DisplayName}</Typography>
            <Typography variant="body1">{`DDRS ID: ${formik.values[id.SchemaColumnName]}`}</Typography>
            {lastprocessed && (
              <Typography variant="body1">{`Last Processed: ${formik.values[lastprocessed.SchemaColumnName]}`}</Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {processorder && (
              <Box sx={{ mt: 3, mb: 1, flexShrink: 1 }}>
                <GetControl
                  control={{
                    ControlTypeID: processorder.ControlTypeID,
                    DimensionTypeID: processorder.DimensionTypeID,
                    DisplayName: processorder.DisplayName,
                    name: processorder.SchemaColumnName,
                    value: formik.values[processorder.SchemaColumnName],
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    disabled: !formik.values[id.SchemaColumnName],
                    items: processorder.items
                  }}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {Object.values(groups).map((group) => renderGroup(group))}
    </Grid>
  );
};

export default DDRSForm;
