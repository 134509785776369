import { useRef, useState } from 'react';

/**
 * This hook is used to set a state, after a particular duration.
 * But have the ability to clear the state change if needed before it has taken affect.
 *
 * This is useful for situations where we need to show tooltips after a particular timeout.
 *
 * @param initialState
 * @param timeout
 */
const useStateTimeout = (initialState, timeout) => {
  const [state, setState] = useState(initialState);
  const stateTimeout = useRef(null);

  const onStateSet = (newState) => {
    if (stateTimeout.current) {
      return;
    }

    stateTimeout.current = setTimeout(() => {
      setState(newState);
      stateTimeout.current = undefined;
    }, timeout);
  };

  const onStateClear = () => {
    setState(initialState);
    if (stateTimeout.current) {
      clearTimeout(stateTimeout.current);
      stateTimeout.current = undefined;
    }
  };

  return [state, onStateSet, onStateClear];
};

export default useStateTimeout;
