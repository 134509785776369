import _ from 'lodash';
import Select from './components/editor-types/Select';
import SelectMulti from './components/editor-types/SelectMulti';
import Text from './components/editor-types/Text';
import Search from './components/editor-types/Search';
import TextArea from './components/editor-types/TextArea';

export const EDIT_TYPES = {
  text: Text,
  textarea: TextArea,
  checkbox: Select, // TODO: Implement
  select: Select,
  multiselect: SelectMulti,
  search: Search,
};

export const getEditType = (type) => (_.has(EDIT_TYPES, type) ? EDIT_TYPES[type] : EDIT_TYPES.text);
