import { useSelector } from 'react-redux';
import { useRef } from 'react';

const useSelectorCondition = (func, initialValue, condition) => {
  const storedRef = useRef(initialValue);

  return useSelector((state) => {
    if (!condition) {
      return storedRef.current;
    }
    storedRef.current = func(state);
    return storedRef.current;
  });
};

export default useSelectorCondition;
