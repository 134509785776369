import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  chain,
  map, uniqueId,
} from 'lodash';
import config from '../config';

export const entityFilterAdapter = createEntityAdapter({
  selectId: (entity) => entity.name,
  sortComparer: (a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0),
});

const createInitialState = () => entityFilterAdapter.addMany(
  entityFilterAdapter.getInitialState(),
  map(config.filter, (entity, sortOrder) => ({
    ...entity,
    sortOrder,
    clearGroupId: entity.clearGroupId ?? uniqueId(),
  })),
);

const FilterReducer = createSlice({
  name: 'filter',
  initialState: {
    ...createInitialState(),
    isInitialised: false,
    isChanged: false,
  },
  reducers: {
    clearFilterState: (state) => {
      const filters = entityFilterAdapter.getSelectors().selectAll(state);
      entityFilterAdapter.updateMany(state, map(filters, (filter) => ({ id: filter.name, changes: { state: filter.initialState } })));
      state.isChanged = false;
    },
    setFilterState: (state, action) => {
      // Clear any other filters in the same group.
      const filters = entityFilterAdapter.getSelectors().selectEntities(state);
      const filterChanges = (
        chain(filters)
          .filter((filter) => filter.clearGroupId === filters[action.payload.name].clearGroupId)
          .map((filter) => ({
            id: filter.name,
            changes: {
              state: action.payload.name === filter.name ? action.payload.value : filter.initialState,
            },
          }))
          .value()
      );

      entityFilterAdapter.updateMany(state, filterChanges);
      state.isChanged = true;
    },
    loadFilterPreferences: (state, action) => {
      const filters = entityFilterAdapter.getSelectors().selectEntities(state);
      entityFilterAdapter.updateMany(state, map(action.payload?.values, (value, name) => {
        if (name !== filters[name]?.initialState) {
          state.isChanged = true;
        }
        return { id: name, changes: { state: value } };
      }));
      state.isInitialised = true;
    },
  },
});

export default FilterReducer.reducer;
export const { setFilterState, clearFilterState, loadFilterPreferences } = FilterReducer.actions;
